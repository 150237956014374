/*
* Author: Dima Bozhenko
* Date Created: 15.01.2019
* Date Modified: 15.03.2019
* Description: Profile page
* Modification Log:
* License:
*/

import React, {Component, Fragment} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader/Loader';
import {ActionUpdateProfile, ActionGetProfileData} from '../../store/actions/actions-user';
import {ActionRouteNavigate} from '../../store/actions/actions-route';
import './Profile.less';
import ROUTES from '../../configs/routes';

const imagePlaceholder = '/images/required/user-placeholder.png';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      full_name: '',
      mobile_number: '',
      email: '',
      img: '',
    };
  }

  componentDidMount() {
    const {profileData} = this.props;

    this.setState({
      email: profileData.email,
      full_name: profileData.full_name,
      img: profileData.image_encoded_content,
      mobile_number: profileData.mobile_number || '',
    });
  }

  componentWillReceiveProps(props) {
    const {profileData} = props;

    this.setState({
      email: profileData.email,
      full_name: profileData.full_name,
      img: profileData.image_encoded_content,
      mobile_number: profileData.mobile_number,
    });
  }

  editClick = () => {
    this.setState({
      editable: true,
    });
  };

  stopEdit = () => {
    this.setState({
      editable: false,
    });
  };

  inputChange = (value, type) => {
    this.setState({
      [type]: value,
    });
  };

  onImageChangeHandle = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      this.setState({
        img: reader.result,
      });
    };
    reader.onerror = (error) => {
      alert('Error: ', error);
    };
  };

  changePassword = (e) => {
    this.props.ActionRouteNavigate(ROUTES.PROFILE_CHANGE_PASSWORD);
  };

  updateProfile = () => {
    const data = {
      full_name: this.state.full_name,
      mobile_number: this.state.mobile_number,
      email: this.state.email,
      image_encoded_content: this.state.img,
    };

    this.props.ActionUpdateProfile(data, true);

    this.setState({
      editable: false,
    });
  };

  render() {
    const isEditable = !this.state.editable;
    const {isLoading, profileData} = this.props;
    const {img, editable, full_name, email, mobile_number} = this.state;

    return (
      <div className="app-container">
        <div className="app-content">
          <Header logo={false} title="My Profile" />
          <div className="profile">
            <button className="edit-btn" onClick={this.editClick} />
            <div className="-image">
              <img src={`${img || imagePlaceholder}`} />
            </div>
            {!editable &&
            <Fragment>
              <div className="-name">{full_name}</div>
              <div className="-email">{email}</div>
            </Fragment>
            }
            {editable &&
              <div className="-file">
                <input type="file" accept="image/x-png,image/gif,image/jpeg" onChange={this.onImageChangeHandle} />
                <span>Upload new picture</span>
              </div>
            }

            <div className="-info">
              <div className="-field">
                <div className="-field_title">Full Name</div>
                <input
                  type="text"
                  readOnly={isEditable}
                  value={full_name}
                  onChange={e => this.inputChange(e.target.value, 'full_name')}
                />
              </div>
              {/*<div className="-field">*/}
                {/*<div className="-field_title">Username</div>*/}
                {/*<input*/}
                  {/*type="text"*/}
                  {/*readOnly={isEditable}*/}
                  {/*value={this.state.userName}*/}
                  {/*onChange={e => this.inputChange(e.target.value, 'userName')}*/}
                {/*/>*/}
              {/*</div>*/}
              <div className="-field">
                <div className="-field_title">Mobile Number</div>
                <input
                  type="text"
                  readOnly={isEditable}
                  value={mobile_number}
                  onChange={e => this.inputChange(e.target.value, 'mobile_number')}
                />
              </div>
              {/*<div className="-field">*/}
                {/*<div className="-field_title">Carrier</div>*/}
                {/*<input*/}
                  {/*type="text"*/}
                  {/*readOnly={isEditable}*/}
                  {/*value={this.state.carrier}*/}
                  {/*onChange={e => this.inputChange(e.targer.value, 'carrier')}*/}
                {/*/>*/}
              {/*</div>*/}
              <div className="-field">
                <div className="-field_title">Email Id</div>
                <input
                  type="text"
                  readOnly={isEditable}
                  disabled={!isEditable}
                  value={email}
                  onChange={e => this.inputChange(e.target.value, 'email')}
                />
              </div>
              {!editable && !profileData.facebookLogin &&
              <div className="-change-psw_wrap">
                <button className="-change-psw" onClick={this.changePassword}> Change password</button>
              </div>
              }
            </div>
            {editable &&
            <div className="-btn_wrap">
              <button onClick={this.stopEdit} className="-btn-empty">Exit</button>
              <button onClick={this.updateProfile} className="-btn-fill">Update</button>
            </div>
            }
          </div>
        </div>
        <Loader isLoading={isLoading} />
      </div>
    );
  }
}

function mapStateToProps({rUser, rPages}) {
  return {
    profileData: rUser,
    isLoading: rPages.ProfilePage.isLoading || false,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionGetProfileData,
    ActionUpdateProfile,
    ActionRouteNavigate,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
