/*
* Author: Dima Bozhenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Constants
* Modification Log:
* License:
*/

const URL_MAP = {
  EXAMPLE: 'example',
  LOGIN: 'login',
  AGE_CONFIRM: 'age-confirmation',
  WELCOME: 'welcome',
  SIGNUP: 'signup',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  DASHBOARD: 'dashboard',
  PROFILE: 'profile',
  CHANGE_PASSWORD: 'change-password',
  FAVORITES: 'favorites',
  SUPPORT: 'support',
  BAR_DETAIL: 'bar-detail',
  BEER_DETAIL: 'beer-detail',
  BARS: 'bars',
  BEERS: 'beers',
  STYLES: 'styles',
  FAQ: 'faq',
  PRIVACY: 'privacy',
  TERMS: 'terms',
  BEER_LIST: 'beer-list',
  BEER_CLUB: 'beer-club',
  LOCATION: 'location',

};

export default URL_MAP;
