/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Constants
* Modification Log:
* License:
*/

export const ACTIONS = {
  IS_LOADING: 'IS_PAGE_LOADING',
  RESTORE_PAGE: 'RESTORE_PAGE_STATE',
  UPDATE: 'UPDATE_PAGE_FLOW',
};
