/*
* Author: Dima Bozhenko
* Date Created: 04.02.2019
* Date Modified: 15.03.2019
* Description: Modal component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ModalBaseLayout from '../ModalBaseLayout';
import {ActionModalHide} from '../../../store/actions/actions-modal';
import {ActionRouteNavigate} from '../../../store/actions/actions-route';

class ModalProfileUpdateSuccess extends Component {
  constructor(props) {
    super(props);

    this.settings = ModalBaseLayout.getInitialSettings({
      className: 'modal-center',
      closeButton: true,
      keyboard: true,
    });
  }

  loginClickHandle = () => {
    this.props.ActionModalHide();
  };

  render() {
    return (
      <ModalBaseLayout {...this.settings}>
        <div className="modal_box modal_notificate">
          <div className="icon icon--success" />
          <div className="text">Profile Updated Successfully!</div>
          <div className="btn_wrap">
            <button
              className="btn"
              onClick={this.loginClickHandle}
            >
              My Profile
            </button>
          </div>
        </div>
      </ModalBaseLayout>
    );
  }
}

ModalProfileUpdateSuccess.propTypes = {};

function mapStateToProps({}) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionRouteNavigate,
    ActionModalHide,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalProfileUpdateSuccess);
