/*
* Author: Dima Bozhenko
* Date Created: 04.01.2019
* Date Modified: 09.04.2019
* Description: SignUp page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {createForm} from 'rc-form';
import ROUTES from '../../configs/routes';
import {ActionLogin, ActionSignUp, ActionGuestLogin} from '../../store/actions/actions-user';
import Loader from '../../components/Loader/Loader';
import FacebookLoginBtn from '../../components/Facebook/FacebookLoginBtn';
import ValidationUtils from '../../utils/utils-validation';
import './Login.less';

class SignUp extends Component {

  submit = () => {
    this.props.form.validateFields((error, values) => {
      if (!error) {
        this.props.ActionSignUp(values['Full Name'], values.Email, values.Password);
      }
    });
  };

  guestClickHandle = (e) => {
    e.preventDefault();
    this.props.ActionGuestLogin();
  };

  checkPass = (rule, value, callback) => {
    if (!value) {
      callback([new Error('Confirm password is required')]);
    } else if (this.props.form.getFieldValue('Password') !== value) {
      callback([new Error('Confirm password is mismatch with password')]);
    } else {
      callback();
    }
  };

  checkEmail = (rule, value, callback) => {
    if (!value) {
      callback([new Error('Email is required')]);
    } else if (!ValidationUtils.Form.isEmail(value)) {
      callback([new Error('Invalid email format')]);
    } else {
      callback();
    }
  };

  render() {
    const {isLoading} = this.props;
    const {getFieldProps, getFieldError} = this.props.form;
    let errors;

    return (
      <div className="app-container">
        <div className="login">
          <div className="-head">
            <div className="-logo" />
            <div className="-powered">
              <span>Powered By</span>
              <div className="-beerboard" />
            </div>
          </div>
          <div className="-content">
            <div className="-title" style={{marginBottom: '40px'}}>Sign Up</div>
            <div className="-field -field--user">
              <input
                type="text"
                placeholder="Full Name *"
                {...getFieldProps('Full Name', {
                  initialValue: '',
                  rules: [{
                    required: true,
                  }],
                })}
              />
              {(errors = getFieldError('Full Name')) ? <div className="-field_error">{errors.join(',')}</div> : null}
            </div>
            <div className="-field -field--email">
              <input
                type="email"
                placeholder="Email Address *"
                {...getFieldProps('Email', {
                  validateTrigger: false,
                  initialValue: '',
                  rules: [
                    {},
                    {
                      validator: this.checkEmail,
                    },
                  ],
                })}
              />
              {(errors = getFieldError('Email')) ? <div className="-field_error">{errors.join(',')}</div> : null}
            </div>
            <div className="-field -field--password">
              <input
                type="password"
                placeholder="Password *"
                {...getFieldProps('Password', {
                  validateTrigger: false,
                  initialValue: '',
                  rules: [{
                    required: true,
                    min: 6,
                  }],
                })}
              />
              {(errors = getFieldError('Password')) ? <div className="-field_error">{errors.join(',')}</div> : null}
            </div>
            <div className="-field -field--password">
              <input
                type="password"
                placeholder="Confirm Password *"
                {...getFieldProps('Confirm Password', {
                  initialValue: '',
                  rules: [{
                    validator: this.checkPass,
                  }],
                })}
              />
              {(errors = getFieldError('Confirm Password')) ?
                <div className="-field_error">{errors.join(',')}</div> : null}
            </div>
            <div className="-btns_field">
              <button
                className="-btn -btn--signup-dark"
                onClick={this.submit}
              >
                Sign Up
              </button>
              <div className="-divider">or</div>
              <FacebookLoginBtn />
            </div>
            <div className="-text">
              Already have an account? &nbsp;
              <Link to={ROUTES.LOGIN}>LogIn</Link>
            </div>
            <div className="-text">
              Want to try the Application? &nbsp;
              <button className="guest" onClick={this.guestClickHandle}>Be a Guest</button>
            </div>
          </div>
        </div>
        <Loader isLoading={isLoading} />
      </div>
    );
  }
}

SignUp.propTypes = {};

SignUp.defaultProps = {};

function mapStateToProps({rLoginPage, rPages}) {
  return {
    rLoginPage,
    isLoading: rPages.SignUpPage.isLoading || false,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionLogin,
    ActionSignUp,
    ActionGuestLogin,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(createForm()(SignUp));
