/*
* Author: Yatsenko Gennadiy
* Date Created: 03.01.2019
* Date Modified: 15.03.2019
* Description: Modal component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import Modal from 'react-modal';
import _ from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ActionModalShow, ActionModalHide} from '../../store/actions/actions-modal';

class ModalBaseLayout extends Component {
  static getInitialSettings(params) {
    const defaultSettings = {
      className: '',
      backdrop: true,
      closeButton: true,
      onClose: null,
    };

    return {...defaultSettings, ...params};
  }

  hideModal = (e, callback) => {
    this.props.ActionModalHide(callback && _.isFunction(callback) && callback || this.props.onClose);
  };

  render() {
    return (
      <Modal
        className={this.props.className || ''}
        isOpen={!!this.props.rModals.templateType}
        onHide={this.hideModal}
        backdrop={this.props.backdrop}
      >
        {this.props.children}
      </Modal>
    );
  }
}

function mapStateToProps({rModals}) {
  return {
    rModals,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionModalShow,
    ActionModalHide,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalBaseLayout);
