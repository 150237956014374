/*
* Author: Gennady Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Authorizations utils
* Modification Log:
* License:
*/

import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect';

import {USER_ACTIONS} from '../constants/users';
import ROUTES from '../configs/routes';

const locationHelper = locationHelperBuilder({});

export const auth = connectedRouterRedirect({
  allowRedirectBack: true,
  authenticatedSelector: state => !!state.rSession[USER_ACTIONS.LOGGED_IN],
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || ROUTES.AGE_CONFIRM,
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const notAuth = connectedRouterRedirect({
  allowRedirectBack: false,
  authenticatedSelector: state => !state.rSession[USER_ACTIONS.LOGGED_IN],
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || ROUTES.DASHBOARD,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});
