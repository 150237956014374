/*
* Author: Dima Bozhenko
* Date Created: 15.02.2019
* Date Modified: 15.03.2019
* Description: Modal component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ActionModalHide} from '../../../store/actions/actions-modal';
import ModalBaseLayout from '../ModalBaseLayout';

class ModalOpenHours extends Component {
  constructor(props) {
    super(props);

    this.settings = ModalBaseLayout.getInitialSettings({
      className: 'modal-center',
      closeButton: true,
      keyboard: true,
    });
  }

  exitClickHandle = () => {
    this.props.ActionModalHide();
  };

  renderItems = () => {
    const workHours = Object.entries(this.props.workHours);

    return workHours.map((item, index) => {
      if (!item[1] || !item[0]) {
        return null;
      }

      return (
        <div className="oh-item" key={index}>
          <div className="oh-item__day">{item[0]}</div>
          <div className="oh-item__value">OPEN HOURS: {item[1]}</div>
        </div>
      );
    });
  };

  render() {
    return (
      <ModalBaseLayout {...this.settings}>
        <div className="modal_box">
          <div className="oh-items">
            {this.renderItems()}
          </div>
          <button
            className="modal_close"
            onClick={this.exitClickHandle}
          />
        </div>
      </ModalBaseLayout>
    );
  }
}

ModalOpenHours.defaultProps = {
  workHours: {},
};

ModalOpenHours.propTypes = {};

function mapStateToProps({}) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionModalHide,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalOpenHours);
