/*
* Author: Dima Bozhenko
* Date Created: 07.02.2019
* Date Modified: 15.03.2019
* Description: Modal component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import ModalBaseLayout from '../ModalBaseLayout';
import {ActionModalHide} from '../../../store/actions/actions-modal';
import {ActionRouteNavigate} from '../../../store/actions/actions-route';
import {ActionCollectBeerchip} from '../../../store/actions/actions-request';

const WEEKDAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

class ModalChangePasswordSuccess extends Component {
  constructor(props) {
    super(props);

    this.settings = ModalBaseLayout.getInitialSettings({
      className: 'modal-center',
      closeButton: true,
      keyboard: true,
    });

    this.state = {
      receiptId: '',
      showCalendar: false,
      date: new Date(),
      month: new Date(),
      selectedDay: new Date(),
    };
  }

  inputFocusHandle = () => {
    this.setState({
      showCalendar: true,
    });
  };

  collectClickHandle = () => {
    const data = {
      locationId: parseFloat(this.props.locationId),
      checkId: parseFloat(this.state.receiptId),
      checkIdDate: moment(this.state.date).format('YYYY-MM-DD'),
      beerchipId: 1,
    };

    this.props.ActionCollectBeerchip(data);
  };

  exitClickHandle = () => {
    this.props.ActionModalHide();
  };

  inputChange = (value, type) => {
    this.setState({
      [type]: value,
    });
  };

  handleYearMonthChange = (month) => {
    this.setState({month});
  };

  customNavCalendar = (date, localeUtils) => {
    const months = localeUtils.getMonths();
    const month = date.getMonth();
    const year = date.getFullYear();

    const prevYear = (e) => {
      e.preventDefault();
      this.handleYearMonthChange(new Date(year - 1, month));
    };

    const nextYear = (e) => {
      e.preventDefault();
      this.handleYearMonthChange(new Date(year + 1, month));
    };

    const prevMonth = (e) => {
      e.preventDefault();
      this.handleYearMonthChange(new Date(year, month - 1));
    };

    const nextMonth = (e) => {
      e.preventDefault();
      this.handleYearMonthChange(new Date(year, month + 1));
    };

    return (
      <div className="DayPicker-Caption">
        <div className="DayPicker-Section">
          <button className="prev-btn" onClick={prevYear} />
          {date.getFullYear()}
          <button className="next-btn" onClick={nextYear} />
        </div>
        <div className="DayPicker-Section">
          <button className="prev-btn" onClick={prevMonth} />
          {months[month]}
          <button className="next-btn" onClick={nextMonth} />
        </div>
      </div>
    );
  };

  handleDayClick = (day, {selected}) => {
    this.setState({
      selectedDay: selected ? undefined : day,
    });
  };

  closeCalendar = () => {
    this.setState({
      showCalendar: false,
    });
  };

  applyDate = () => {
    this.setState(prevState => ({
      showCalendar: false,
      date: prevState.selectedDay,
    }));
  };

  render() {
    return (
      <ModalBaseLayout {...this.settings}>
        <div className={`modal_box modal_beerchip ${this.state.showCalendar ? 'hide' : ''}`}>
          <div className="title">Collect beerchip</div>
          <div className="field">
            <div className="field_title">Enter Date</div>
            <input
              type="text"
              placeholder="Select Date*"
              value={moment(this.state.date).format('MM-DD-YYYY')}
              onChange={() => {}}
              onFocus={this.inputFocusHandle}
            />
          </div>
          <div className="field">
            <div className="field_title">Enter Receipt Id</div>
            <input
              type="text"
              inputMode="numeric"
              pattern="\d*"
              placeholder="Receipt Id*"
              value={this.state.receiptId}
              onChange={e => this.inputChange(e.target.value, 'receiptId')}
            />
          </div>
          <div className="btn_wrap">
            <button
              className="btn"
              onClick={this.collectClickHandle}
              disabled={!(this.state.date && this.state.receiptId.length >= 4)}
            >
              Collect
            </button>
          </div>
          <div className="-disclaimer">
            <div className="-disclaimer_title">
              DISCLAIMER:
            </div>
            <ol>
              <li>Maximum of 1 redemption per check</li>
              <li>Maximum of 1 redemption per guest per day</li>
              <li>BeerChip Redemption can take up to 24 hours</li>
              <li>Only guests over the age of 21 can participate in the Beer Club</li>
            </ol>
          </div>
          <button
            className="modal_close"
            onClick={this.exitClickHandle}
          />
        </div>
        {this.state.showCalendar &&
        <div className="datepick">
          <div className="datepick_overlay" />
          <div className="datepick_box">
            <div className="datepick_title">Select Date</div>
            <div className="datepick_date">{moment(this.state.selectedDay).format('MM-DD-YYYY')}</div>
            <DayPicker
              month={this.state.month}
              selectedDays={this.state.selectedDay}
              onDayClick={this.handleDayClick}
              weekdaysShort={WEEKDAYS_SHORT}
              captionElement={({date, localeUtils}) => (
                this.customNavCalendar(date, localeUtils)
              )}
            />
            <div className="datepick_btn">
              <button className="-cancel" onClick={this.closeCalendar}>Cancel</button>
              <button className="-apply" onClick={this.applyDate}>Apply</button>
            </div>
          </div>
        </div>
        }
      </ModalBaseLayout>
    );
  }
}

ModalChangePasswordSuccess.propTypes = {};

function mapStateToProps({}) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionRouteNavigate,
    ActionModalHide,
    ActionCollectBeerchip,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalChangePasswordSuccess);
