/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 06.05.2019
* Description: Session reducer
* Modification Log:
* License:
*/

import _ from 'lodash';
import {initialSessionModel} from '../models/session-model';
import {ACTIONS} from '../../constants/sessions';

export default (state = initialSessionModel(), action) => {
  let newState;

  switch (action.type) {
  case ACTIONS.START:
    let initialState = initialSessionModel();

    if (_.isObject(action.data)) {
      _.each(action.data, (value, key) => {
        initialState[key] = value;
      });
    }
    return initialState;

  case ACTIONS.END:
    let data = {};

    if (_.isObject(action.data)) {
      _.each(action.data, (value, key) => {
        data[key] = value;
      });
    }

    return data;

  case ACTIONS.UPDATE:
    newState = _.cloneDeep(state);
    newState = {...newState, ...action.data};
    return newState;

  case ACTIONS.CLEAR:
    newState = {};
    return newState;

  default:
    return state;
  }
};
