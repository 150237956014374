/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 11.04.2019
* Description: Constants
* Modification Log:
* License:
*/

export const ACTIONS = {
  START: 'SESSION_START',
  END: 'SESSION_END',
  UPDATE: 'SESSION_UPDATE',
  CLEAR: 'SESSION_CLEAR',
};

export const STORAGE_SESSION_NAMESPACE = 'local_session_name_space';

export const NAMESPACES = {};
