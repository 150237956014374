/*
* Author: Yatsenko Gennadiy
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description:
* Modification Log:
* License:
*/

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {PersistGate} from 'redux-persist/integration/react';
import Modal from 'react-modal';
import Routes from './Routes';
import {store, persistor, history} from './store/store';
import UtilHelper from './utils/utils-helper';
import './less/main.less';

UtilHelper.setAppVersion();
Modal.setAppElement('#root');
history.listen(() => {
  window.scrollTo(0, 0);
});


ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Routes history={history} store={store} />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
