/*
* Author: Dima Bozhenko
* Date Created: 24.01.2019
* Date Modified: 15.03.2019
* Description: Rate component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Rate.less';

class Rate extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  setRate = (rate) => {
    this.props.setRate(rate + 1);
  };

  renderStar = () => {
    const count = 5;
    const activeCount = this.props.value || 0;
    const countArr = Array.from({length: count}, (v, k) => k);

    return (
      countArr.map((star) => {
        return (
          <div
            key={star}
            className={`-star ${star <= (activeCount - 1) ? 'active' : ''}`}
            onClick={() => this.setRate(star)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 72 69"
              fill="none"
            >
              <path fillRule="evenodd" strokeWidth="6px" strokeLinecap="butt" strokeLinejoin="miter"
                d="M33.243,2.999 C34.284,2.999 35.324,2.999 36.363,2.999 C38.814,6.938 41.342,11.592 43.539,15.812 C44.558,17.768 44.460,19.731 46.659,20.500 C47.695,21.251 48.777,20.733 50.407,21.124 C54.866,22.197 61.030,22.253 65.070,23.937 C65.696,25.185 66.024,25.864 66.005,28.000 C62.466,30.671 59.467,34.548 56.333,37.687 C55.198,38.825 53.039,40.035 52.587,41.750 C52.172,43.332 53.247,45.644 53.525,46.749 C53.525,47.582 53.525,48.416 53.525,49.249 C54.391,52.695 55.756,57.334 55.084,61.124 C53.814,61.972 53.408,62.821 51.342,62.999 C49.323,61.196 46.333,60.585 43.851,59.249 C40.941,57.682 38.130,54.934 34.178,54.562 C32.451,55.907 30.255,56.295 28.250,57.375 C24.953,59.150 21.287,62.546 17.017,62.999 C15.063,61.251 14.103,61.427 14.205,57.375 C14.939,56.340 14.446,55.199 14.831,53.624 C15.333,51.572 17.711,43.231 16.393,41.125 C14.253,35.270 3.607,32.863 3.598,24.875 C4.561,24.439 5.079,23.337 6.097,22.999 C7.133,22.999 8.174,22.999 9.214,22.999 C13.568,21.914 19.151,21.531 23.568,20.500 C26.950,15.000 29.371,8.067 33.243,2.999 Z"/>
            </svg>
          </div>
        );
      })
    );
  };

  render() {
    return (
      <div className="rate">
        {this.renderStar()}
      </div>
    );
  }
}

Rate.propTypes = {
  setRate: PropTypes.func,
};

Rate.defaultProps = {
  setRate: () => {},
};

export default Rate;
