/*
* Author: Dima Bozhenko
* Date Created: 04.01.2019
* Date Modified: 11.04.2019
* Description: User actions
* Modification Log:
* License:
*/

import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import ConstantsStorage from '../../constants/index';
import {NotificationError} from '../../services/notifications';
import UserApi from '../../api/user';
import {ActionSessionStart, ActionSessionEnd} from './actions-session';
import {ActionStoreServerData, ActionClearServerData} from './actions-request';
import {ActionRouteNavigate} from './actions-route';
import {ActionPagesUpdateState} from './actions-pages';
import ROUTES from '../../configs/routes';
import CommonAPI from '../../api/common';
import {
  ActionModalRegisterSuccess,
  ActionModalNotFoundUser,
  ActionModalChangePasswordSuccess,
  ActionModalChangePasswordNotSuccess,
  ActionModalNotFoundAccount,
  ActionModalProfileUpdateSuccess,
  ActionModalRegisterNotSuccess,
} from './actions-modal';
import UtilsWeb from '../../utils/utils-web';

const locationHelper = locationHelperBuilder({});
const Constants = ConstantsStorage();
const {USER_ACTIONS} = Constants;

export function ActionUserUpdate(data, remember) {
  return {
    type: USER_ACTIONS.UPDATE,
    data,
    remember,
  };
}

export function ActionUserClear() {
  return {
    type: USER_ACTIONS.CLEAR,
    data: {},
  };
}

export function ActionLogin(email, password, rememberMe = false) {
  return (dispatch, getState) => {
    const sessionData = {};

    dispatch(ActionPagesUpdateState('LoginPage', {isLoading: true}));

    return UserApi.login(email, password, rememberMe)
      .then((res) => {
        let {userData} = res;

        UtilsWeb.getLocation().then((coordinates) => {
          CommonAPI.getProfileData(userData.id).then((res) => {
            const state = getState();
            const user = state.rUser || {};

            userData = {
              ...user,
              ...userData,
              ...res.Data[0],
            };

            if (coordinates.latitude && coordinates.longitude) {
              userData.latitude = coordinates.latitude;
              userData.longitude = coordinates.longitude;
            }

            sessionData[Constants.USER_ACTIONS.LOGGED_IN] = true;

            if (rememberMe) {
              sessionData.loginData = {
                email,
                password,
              };
            }

            dispatch(ActionUserUpdate(userData, rememberMe));
            dispatch(ActionSessionStart(sessionData, rememberMe));

            const nextUrl = (state.router.location.search &&
              state.router.location.search.length) ?
              locationHelper.getRedirectQueryParam(state.router) :
              ROUTES.DASHBOARD;
            dispatch(ActionRouteNavigate(nextUrl));
          });
        });
      }, () => {
        dispatch(ActionPagesUpdateState('LoginPage', {isLoading: false}));
        dispatch(ActionModalNotFoundAccount());
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(ActionPagesUpdateState('LoginPage', {isLoading: false}));
        }, 2000);
      });
  };
}

export function ActionFacebookLogin(data, rememberMe = false) {
  return (dispatch, getState) => {
    const sessionData = {};
    const reqData = {
      email: data.email,
      full_name: data.name,
      conceptId: 7,
      beta: 0,
    };

    dispatch(ActionPagesUpdateState('LoginPage', {isLoading: true}));

    return UserApi.facebookLogin(reqData, rememberMe)
      .then((res) => {
        UtilsWeb.getLocation()
          .then((coordinates) => {
            CommonAPI.getProfileData(res.UserId)
              .then((res) => {
                const userData = {
                  ...res.Data[0],
                  facebookLogin: true,
                };

                if (coordinates.latitude && coordinates.longitude) {
                  userData.latitude = coordinates.latitude;
                  userData.longitude = coordinates.longitude;
                }

                userData.image_encoded_content = userData.image_encoded_content ? userData.image_encoded_content : data.picture.data.url;
                sessionData[Constants.USER_ACTIONS.LOGGED_IN] = true;

                dispatch(ActionUserUpdate(userData, rememberMe));
                dispatch(ActionSessionStart(sessionData, rememberMe));

                const state = getState();
                const nextUrl = (state.router.location.search &&
                  state.router.location.search.length) ?
                  locationHelper.getRedirectQueryParam(state.router) :
                  ROUTES.DASHBOARD;

                dispatch(ActionRouteNavigate(nextUrl));
              });
          });
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('LoginPage', {isLoading: false}));
      });
  };
}

export function ActionSignUp(fullName, email, password) {
  return (dispatch) => {
    dispatch(ActionPagesUpdateState('SignUpPage', {isLoading: true}));

    return UserApi.signUp(fullName, email, password)
      .then((res) => {
        if (res.Data.Success) {
          dispatch(ActionModalRegisterSuccess());
        }

        if (!res.Data.Success) {
          dispatch(ActionModalRegisterNotSuccess({message: res.Data.Message}));
        }
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('SignUpPage', {isLoading: false}));
      });
  };
}

export function ActionGuestLogin(email, password, rememberMe = false) {
  return (dispatch, getState) => {
    dispatch(ActionPagesUpdateState('LoginPage', {isLoading: true}));

    UtilsWeb.getLocation().then((res) => {
      const sessionData = {};
      sessionData[Constants.USER_ACTIONS.LOGGED_IN] = true;
      const userData = {
        id: 0,
        conceptId: 7,
        guest: true,
        ...res,
      };

      dispatch(ActionUserUpdate(userData, rememberMe));
      dispatch(ActionSessionStart(sessionData, rememberMe));

      const state = getState();
      const nextUrl = (state.router.location.search &&
        state.router.location.search.length) ?
        locationHelper.getRedirectQueryParam(state.router) :
        ROUTES.DASHBOARD;

      dispatch(ActionRouteNavigate(nextUrl));
      dispatch(ActionPagesUpdateState('LoginPage', {isLoading: false}));
    });
  };
}

export function ActionRecoverPassword(email) {
  return (dispatch) => {
    dispatch(ActionPagesUpdateState('ForgotPasswordPage', {isLoading: true}));
    dispatch({
      type: USER_ACTIONS.UPDATE,
      data: {
        RecoverPassword: {
          inProgress: true,
          emailValidationState: null,
          passwordValidationState: null,
        },
      },
    });

    return UserApi.forgotPassword(email)
      .then((res) => {
        if (res.success) {
          const data = {
            email,
          };

          dispatch(ActionStoreServerData('resetPasswordData', data));
          dispatch(ActionRouteNavigate(ROUTES.RESET_PASSWORD));
        }

        if (!res.success) {
          dispatch(ActionModalNotFoundUser());
        }
      })
      .finally(() => {
        dispatch({
          type: USER_ACTIONS.UPDATE,
          data: {
            RecoverPassword: {
              inProgress: false,
            },
          },
        });
        dispatch(ActionPagesUpdateState('ForgotPasswordPage', {isLoading: false}));
      });
  };
}

export function ActionResendOTP(email) {
  return (dispatch) => {
    dispatch(ActionPagesUpdateState('ResetPasswordPage', {isLoading: true}));

    return UserApi.forgotPassword(email)
      .then((res) => {
        if (res.success) {
          const data = {
            email,
          };
          dispatch(ActionPagesUpdateState('isHasBeenSentOTP', true));
          dispatch(ActionStoreServerData('resetPasswordData', data));
        }
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('ResetPasswordPage', {isLoading: false}));
      });
  };
}

export function ActionOTPClear() {
  return (dispatch) => {
    dispatch(ActionPagesUpdateState('isHasBeenSentOTP', false));
  };
}

export function ActionResetPassword(data) {
  return (dispatch) => {
    return UserApi.resetPassword(data)
      .then((res) => {
        if (res.success) {
          dispatch(ActionModalChangePasswordSuccess());
          dispatch(ActionStoreServerData('resetPasswordData', {}));
        }

        if (!res.success) {
          dispatch(ActionModalChangePasswordNotSuccess());
        }
      })
      .finally(() => {
      });
  };
}

export function ActionSignOut() {
  return (dispatch, getState) => {
    if (window.FB) {
      window.FB.logout();
    }
    const data = {};
    const loginData = getState().rSession.loginData || {};
    data.loginData = loginData;

    dispatch({type: USER_ACTIONS.SIGN_OUT});
    dispatch(ActionSessionEnd(data));
    dispatch(ActionUserClear());
    dispatch(ActionClearServerData());
    dispatch(ActionRouteNavigate(ROUTES.LOGIN));
  };
}

export function ActionGetProfileData() {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.rUser.id;

    dispatch(ActionPagesUpdateState('ProfilePage', {isLoading: true}));

    return CommonAPI.getProfileData(userId)
      .then((res) => {
        dispatch(ActionUserUpdate(res.Data[0], true));
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('ProfilePage', {isLoading: false}));
      });
  };
}

export function ActionUpdateProfile(data) {
  return (dispatch, getState) => {
    const state = getState();
    const {id} = state.rUser;
    const conceptId = 7;

    dispatch(ActionPagesUpdateState('ProfilePage', {isLoading: true}));

    return CommonAPI.updateProfileData(data, id, conceptId)
      .then((res) => {
        if (res.Success) {
          dispatch(ActionModalProfileUpdateSuccess());
          dispatch(ActionGetProfileData());
        }
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('ProfilePage', {isLoading: false}));
      });
  };
}

export function ActionChangePassword(data) {
  return (dispatch, getState) => {
    const state = getState();
    const {id} = state.rUser;
    const conceptId = 7;

    dispatch(ActionPagesUpdateState('ChangePasswordPage', {isLoading: false}));

    return CommonAPI.changePassword(data, id, conceptId)
      .then((res) => {

        if (res.success) {
          dispatch(ActionModalChangePasswordSuccess());
          dispatch(ActionSignOut());
        }

        if (!res.success) {
          NotificationError(res.message);
        }
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('ChangePasswordPage', {isLoading: false}));
      });
  };
}

export function ActionGetPosition() {
  return (dispatch, getState) => {
    const watchID = navigator.geolocation.watchPosition((position) => {
      const userData = getState.rUser || {};
      userData.latitude = position.coords.latitude;
      userData.longitude = position.coords.longitude;

      dispatch(ActionUserUpdate(userData));
    });
  };
}
