/*
* Author: Dima Bozhenko
* Date Created: 24.01.2019
* Date Modified: 15.03.2019
* Description: Modal component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ModalBaseLayout from '../ModalBaseLayout';
import Rate from '../../UI/Rate/Rate';
import {ActionModalHide} from '../../../store/actions/actions-modal';
import {ActionRateBeer} from '../../../store/actions/actions-request';

class ModalBeerRate extends Component {
  constructor(props) {
    super(props);

    this.settings = ModalBaseLayout.getInitialSettings({
      className: 'modal-center',
      closeButton: true,
      keyboard: true,
    });

    this.state = {
      rate: this.props.value,
    };
  }

  setRate = (rate) => {
    this.setState({
      rate,
    });
  };

  rateBeer = () => {
    const data = {
      user_id: this.props.rUser.id,
      beer_id: this.props.beerId,
      value: this.state.rate,
      conceptId: 7,
    };

    this.props.ActionRateBeer(data);
    this.props.ActionModalHide();
  };

  exitClickHandle = () => {
    this.props.ActionModalHide();
  };

  render() {
    return (
      <ModalBaseLayout {...this.settings}>
        <div className="modal_box modal_rate">
          <Rate setRate={this.setRate} value={this.state.rate} />
          <div className="text">Rate {this.props.beerName}</div>
          <div className="btn_wrap">
            <button
              className="btn"
              onClick={this.rateBeer}
            >
              Rate Beer
            </button>
          </div>
          <button
            className="modal_close"
            onClick={this.exitClickHandle}
          />
        </div>
      </ModalBaseLayout>
    );
  }
}

ModalBeerRate.propTypes = {};

function mapStateToProps({rUser}) {
  return {
    rUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionModalHide,
    ActionRateBeer,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalBeerRate);
