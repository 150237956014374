/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Flow model
* Modification Log:
* License:
*/

function initialFlowModel() {
  return {
    LoginPage: {},
    ForgotPasswordPage: {},
    ResetPasswordPage: {},
    isHasBeenSentOTP: false,
    SignUpPage: {},
    ProfilePage: {},
    FavoritesPage: {},
    BeerDetailPage: {},
    BarDetailPage: {},
    DashboardPage: {},
    BarDetailData: {},
    BarMenu: {},
    UserBeerRating: {},
    SearchLocationInput: {},
    SearchBeerInput: {},
  };
}

export default initialFlowModel;
