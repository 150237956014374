/*
* Author: Dima Bozhenko
* Date Created: 15.01.2019
* Date Modified: 11.04.2019
* Description: Dashboard page
* Modification Log:
* License:
*/

import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import _ from 'lodash';
import {connect} from 'react-redux';
import {buildRoute} from '../../configs/routes';
import URLS from '../../constants/urls';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader/Loader';
import SearchInput from '../../components/UI/SearchInput/SearchInput';
import {
  ActionSearchBeer,
  ActionSearchBeerCleanList,
  ActionLoadBarList,
  ActionLoadLocations,
  ActionLoadBeerList,
  ActionGetLocationBars,
  ActionSetLocation,
  ActionClearLocation,
} from '../../store/actions/actions-request';
import './Dashboard.less';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.defaultLocation = 'Current Location';

    this.state = {
      searchLocation: props.currentLocation || this.defaultLocation,
      searchBeer: '',
      searchLocations: null,
      searchBeerItems: null,
    };

    this.debounceSearchBeer = _.debounce(this.searchBeer, 1000);
    this.searchLocationTimeout = _.debounce(this.searchLocation, 1000);
    this.locationResultsRef = React.createRef();
    this.beerInputRef = React.createRef();
    this.locInputRef = React.createRef();
  }

  componentDidMount() {
    const {searchLocation} = this.state;

    if (searchLocation !== this.defaultLocation) {
      this.searchBars();
    } else {
      this.getLocationBars();
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({searchLocations: nextProps.locations});
    this.setState({searchBeerItems: nextProps.beerList});
  }

  onBeerSearch = (e) => {
    const {value} = e.target;

    if (!value.length) this.clearBeer();

    this.setState({
      searchBeer: value,
    }, () => {
      this.debounceSearchBeer();
    });
  };

  onLocationSearch = (e) => {
    const {value} = e.target;

    this.setState({
      searchLocation: value,
    }, () => {
      if (!value) {
        this.props.ActionClearLocation();
      }

      if (value.length > 2) {
        this.searchLocationTimeout();
      }
    });
  };

  clearLocation = () => {
    this.setState({
      searchLocation: '',
      searchLocations: null,
    });

    this.props.ActionSetLocation();
    this.props.ActionClearLocation();
  };

  clearBeer = () => {
    this.setState({
      searchBeer: '',
      searchBeerItems: null,
    }, () => {
      const {searchLocation} = this.state;
      const location = searchLocation === this.defaultLocation ? null : searchLocation;
      if (location) {
        this.searchBars();
      }
    });
  };

  searchLocation = () => {
    const {searchLocation} = this.state;
    const searchedValue = searchLocation === this.defaultLocation ? '' : searchLocation;

    if (searchLocation.length > 2) {
      this.props.ActionLoadLocations(searchedValue);
    } else {
      this.clearLocation();
    }
  };

  searchBeer = () => {
    const {searchLocation, searchBeer} = this.state;

    if (searchBeer.length > 2) {
      const location = searchLocation === this.defaultLocation ? null : searchLocation;

      this.props.ActionLoadBeerList(searchBeer, location);
    }
  };

  searchBars = () => {
    this.props.ActionLoadBarList(this.state.searchLocation);
  };

  getLocationBars = (location) => {
    this.props.ActionGetLocationBars(location);
  };

  renderBars = bars => bars.map((bar, index) => {
    return (
      <Link to={buildRoute(URLS.BAR_DETAIL, URLS.BEER_LIST, `${bar.locationId}`)} className="-block" key={index}>
        <div className="-title">{bar.boardname}</div>
        <div className="-descr">
          {bar.addrStreet}
          <br />
          {`${bar.addrCity}, ${bar.addrState}, ${bar.addrZip}`}
        </div>
        <div className="-section">
          <div className="-info">
            Open Hours &nbsp;
            {bar.hours}
          </div>
          <div className="-info">{bar.distance ? parseFloat(bar.distance).toFixed(1) : bar.distance} Miles</div>
        </div>
      </Link>
    );
  });

  renderLocations = () => {
    const items = [...this.state.searchLocations] || [];

    items.unshift({City: this.defaultLocation});

    return items.map((item, index) => {
      const clickHandler = () => {
        this.setState({searchLocation: item.City}, () => {
          if (this.state.searchLocation === this.defaultLocation) {
            this.getLocationBars();
          } else {
            this.searchBars();
          }
          this.props.ActionSetLocation(item.City);
        });
      };

      return (
        <li key={index}>
          <button
            type="button"
            onClick={clickHandler}
          >
            {item.City}
          </button>
        </li>
      );
    });
  };

  renderBeer = (beerList) => {
    const items = beerList || [];

    if (items.length === 0) {
      return (
        <div className="-block -notfound">
          <div className="-title">Sorry! No Beers found</div>
        </div>
      );
    }

    return items.map(item => (
      <Link to={buildRoute(URLS.BEER_DETAIL, item.id)} className="-block -searcheble" key={item.id}>
        <div className="-image" />
        <div className="-title">{item.name}</div>
      </Link>
    ));
  };

  render() {
    const {searchBeerList, beerList, bars, isLoading, searchBarList,
      isLoadingSearchBeer, isLoadingSearchLocation, currentLocation} = this.props;
    const beersFound = beerList && !!this.state.searchBeer.length;
    const {searchLocations, searchLocation, searchBeer, searchBeerItems} = this.state;

    return (
      <div className="app-container">
        <Header />
        <div className={`app-content dashboard ${searchLocations && !searchBeerItems ? 'fixed' : ''}`}>
          <div
            className="dashboard_head"
          >
            <div className="dashboard_head-wrap">
              <SearchInput
                placeholder="Search Beer & Stores"
                value={searchBeer}
                onChange={this.onBeerSearch}
                cleanInput={this.clearBeer}
                searchList={searchBeerList}
                forwardedRef={this.beerInputRef}
                isLoading={isLoadingSearchBeer}
              />
              {!beersFound
              && (
                <Fragment>
                  <div className="search-field loc">
                    <input
                      type="text"
                      placeholder="Search location"
                      value={searchLocation}
                      onChange={this.onLocationSearch}
                      ref={this.locInputRef}
                    />
                    {!!searchLocation.length
                    && (
                      <button
                        className="search-field_clean"
                        onClick={this.clearLocation}
                      />
                    )}
                    {!isLoadingSearchLocation && <span className="search-field_loc" />}
                    {isLoadingSearchLocation && <span className="search-field_loading" />}
                  </div>
                  {searchLocations
                  && (
                    <div className="search-results" ref={this.locationResultsRef}>
                      <ul className="search-list">
                        {this.renderLocations()}
                      </ul>
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          </div>
          <div className="dashboard_body">
            {beersFound &&
              <div className="beers">
                {this.renderBeer(beerList)}
              </div>
            }
            {(beersFound && !!searchBarList.length) &&
            <div className="section">
              <div className="section_title">Bars</div>
              <div className="bars-near">
                {this.renderBars(searchBarList)}
              </div>
            </div>
            }
            {!!bars.length && !beersFound && (
              <div className="section">
                <div className="section_title">Bars Near You</div>
                <div className="bars-near">
                  {this.renderBars(bars)}
                </div>
              </div>
            )}
            {!isLoading && !bars.length && !beersFound &&
            <div className="-title text-center" style={{textAlign: 'center', marginTop: '150px'}}>
              Sorry! No bars found in your location
            </div>
            }
          </div>
        </div>
        <Loader isLoading={isLoading} />
      </div>
    );
  }
}

function mapStateToProps({rServerData, rPages}) {
  return {
    searchBeerList: rServerData.searchBeerList || [],
    bars: rServerData.bars || [],
    locations: rServerData.barsCities,
    beerList: rServerData.beerList,
    searchBarList: rServerData.searchBarList || [],
    currentLocation: rServerData.currentLocation || '',
    isLoading: rPages.DashboardPage.isLoading || false,
    isLoadingSearchBeer: rPages.SearchBeerInput.isLoading || false,
    isLoadingSearchLocation: rPages.SearchLocationInput.isLoading || false,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionSearchBeer,
    ActionSearchBeerCleanList,
    ActionLoadBarList,
    ActionLoadLocations,
    ActionLoadBeerList,
    ActionGetLocationBars,
    ActionSetLocation,
    ActionClearLocation,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
