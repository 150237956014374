/*
* Author: Gennadiy Yatsenko
* Date Created: 04.02.2019
* Date Modified: 14.03.2019
* Description: Favorites Styles page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ROUTES from '../../configs/routes';
import Header from '../../components/Header/Header';
import Like from '../../components/UI/Like/Like';
import NavBar from '../../components/NavBar/NavBar';
import Loader from '../../components/Loader/Loader';
import {
  ActionGetFavoriteStyles,
  ActionChangeFavoriteStyle,
} from '../../store/actions/actions-request';

class FavoritesBars extends Component {
  componentDidMount() {
    this.props.ActionGetFavoriteStyles();
  }

  likeClickHandle = (value, id) => {
    const data = {
      bm_style_id: id,
      is_favorite: value === 1 ? 0 : 1,
    };

    this.props.ActionChangeFavoriteStyle(data);
  };

  render() {
    const {styles, isLoading} = this.props;
    const tabsData = [
      {
        route: ROUTES.FAVORITES_BARS,
        name: 'BARS',
      },
      {
        route: ROUTES.FAVORITES_BEERS,
        name: 'BEERS',
      },
      {
        route: ROUTES.FAVORITES_STYLES,
        name: 'STYLES',
      },
    ];

    return (
      <div className="app-container">
        <div className="app-content favorites">
          <Header logo={false} title="Favorites" />
          <div className="favorites_head">
            <NavBar activeIndex={2} data={tabsData} />
          </div>
          <div className="favorites_content">
            <div className="styles">
              {
                styles.map((style) => {
                  return (
                    <div className="-block" key={style.id}>
                      <div className="-name">{style.style}</div>
                      <div className="-like">
                        <Like
                          value={style.isFavorite}
                          id={style.id}
                          onClick={() => this.likeClickHandle(style.isFavorite, style.id)}
                        />
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
        <Loader isLoading={isLoading} />
      </div>
    );
  }
}

function mapStateToProps({rServerData, rPages}) {
  return {
    styles: rServerData.styles || [],
    isLoading: rPages.FavoritesPage.isLoading || false,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionGetFavoriteStyles,
    ActionChangeFavoriteStyle,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesBars);
