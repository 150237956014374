/*
* Author: Dima Bozhenko
* Date Created: 04.02.2019
* Date Modified: 14.03.2019
* Description: Forgot Password page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {createForm} from 'rc-form';
import {Link} from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import ROUTES from '../../configs/routes';
import {ActionRecoverPassword} from '../../store/actions/actions-user';
import ValidationUtils from '../../utils/utils-validation';
import './Login.less';

class ForgotPassword extends Component {

  recoverClickHandle = () => {
    this.props.form.validateFields((error, values) => {
      if (!error) {
        this.props.ActionRecoverPassword(values.Email);
      }
    });
  };

  checkEmail = (rule, value, callback) => {
    if (!value) {
      callback([new Error('Email is required')]);
    } else if (!ValidationUtils.Form.isEmail(value)) {
      callback([new Error('Invalid email format')]);
    } else {
      callback();
    }
  };

  render() {
    const {isLoading} = this.props;
    const {getFieldProps, getFieldError} = this.props.form;
    let errors;

    return (
      <div className="app-container">
        <div className="login">
          <div className="-head">
            <div className="-logo" />
            <div className="-powered">
              <span>Powered By</span>
              <div className="-beerboard" />
            </div>
          </div>
          <div className="-content">
            <div className="-title" style={{marginBottom: '40px'}}>Forgot Password</div>
            <div className="-text">
              Enter your email address below to recive your password reset instructions
            </div>
            <div className="-field -field--email">
              <input
                type="email"
                placeholder="Email Address"
                name="email"
                {...getFieldProps('Email', {
                  initialValue: '',
                  rules: [{
                    validator: this.checkEmail,
                  }],
                })}
              />
              {(errors = getFieldError('Email')) ?
                <div className="-field_error">{errors.join(',')}</div> : null}
            </div>
            <div className="-btns_field">
              <button
                className="-btn -btn--login"
                onClick={this.recoverClickHandle}
              >
                SEND
              </button>
            </div>
            <div className="-text">
              Already have a password &nbsp;
              <Link to={ROUTES.LOGIN}>Login</Link>
            </div>
            <div className="-text">
              Are you a new user? &nbsp;
              <Link to={ROUTES.SIGNUP}>Sign Up</Link>
            </div>
          </div>
        </div>
        <Loader isLoading={isLoading} />
      </div>
    );
  }
}

ForgotPassword.propTypes = {};

ForgotPassword.defaultProps = {};

function mapStateToProps({rLoginPage, rPages}) {
  return {
    rLoginPage,
    isLoading: rPages.ForgotPasswordPage.isLoading || false,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionRecoverPassword,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(createForm()(ForgotPassword));
