/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Server data reducer
* Modification Log:
* License:
*/

import {ACTIONS} from '../../constants/data';
import initialServerDataModel from '../models/server-data-model';

export default (state = initialServerDataModel(), action) => {
  let newState;
  switch (action.type) {
  case ACTIONS.UPDATE:
    newState = _.cloneDeep(state);

    if (action.key) {
      let newObject = {};
      newObject[action.key] = action.data;
      return {...newState, ...newObject};
    }

    return {...newState, ...action.data};

  case ACTIONS.CLEAR:
    return {};

  default:
    return state;
  }
};
