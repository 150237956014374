/*
* Author: Dima Bozhenko
* Date Created: 15.01.2019
* Date Modified: 15.03.2019
* Description: RooteLayout component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import {VERSION} from 'configs';
import './Sidebar.less';
import ROUTES from '../../configs/routes';
import {ActionSignOut} from '../../store/actions/actions-user';
import {ActionToggleSidebar} from '../../store/actions/actions-pages';
import {ActionModalShowLogin} from '../../store/actions/actions-modal';
import {ActionRouteNavigate} from '../../store/actions/actions-route';

class Sidebar extends Component {
  signOut = () => {
    this.props.ActionSignOut();
    this.props.ActionToggleSidebar(false);
  };

  onLogin = () => {
    this.props.ActionSignOut();
    this.props.ActionToggleSidebar(false);
    this.props.ActionRouteNavigate(ROUTES.LOGIN);
  };

  closeSidebar = () => {
    this.props.ActionToggleSidebar(false);
  };

  onClickHandle = (e, loginOnly) => {
    this.props.ActionToggleSidebar(false);

    if (this.props.rUser.guest && loginOnly) {
      e.preventDefault();
      this.props.ActionModalShowLogin();
    }
  };

  renderLoginBtn = () => {
    if (this.props.rUser.guest) {
      return (
        <button
          onClick={this.onLogin}
          className="-link --logout"
        >
          Login
        </button>
      );
    }

    return (
      <button
        onClick={this.signOut}
        className="-link --logout"
      >
        Logout
      </button>
    );
  };

  render() {
    const showSidebar = this.props.rPages.sidebar;

    return (
      <div className={`sidebar-wrap ${showSidebar ? 'active' : ''}`}>
        <div className="sidebar">
          <div className="-logo" />
          <div className="-nav">
            <Link className="-link --dashboard" onClick={this.onClickHandle} to={ROUTES.DASHBOARD}>Dashboard</Link>
            <Link className="-link --profile" onClick={e => this.onClickHandle(e, true)} to={ROUTES.PROFILE}>My Profile </Link>
            <Link className="-link --favorites" onClick={e => this.onClickHandle(e, true)} to={ROUTES.FAVORITES_BARS}>Favorites </Link>
            <Link className="-link --support" onClick={this.onClickHandle} to={ROUTES.SUPPORT_FAQ}>Support </Link>
          </div>
          <div className="-footer">
            {this.renderLoginBtn()}
            {/*<div className="-version">Version {VERSION}</div>*/}
            <div className="-powered">
              <span>Powered By</span>
              <div className="-beerboard" />
            </div>
          </div>
        </div>
        <div className="sidebar-layout" onClick={this.closeSidebar} />
      </div>
    );
  }
}

function mapStateToProps({rPages, rUser}) {
  return {
    rPages,
    rUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionSignOut,
    ActionToggleSidebar,
    ActionModalShowLogin,
    ActionRouteNavigate,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
