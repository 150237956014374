/*
* Author: Dima Bozhenko
* Date Created: 24.01.2019
* Date Modified: 15.03.2019
* Description: Like component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import './Like.less';

class Like extends Component {
  clickHandle = () => {
    this.props.onClick();
  };

  render() {
    return (
      <div className={`like ${this.props.value === 1 ? 'active' : ''}`} onClick={this.clickHandle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33px"
          height="32px"
          viewBox="0 0 72 72"
          fill="none"
        >
          <path fillRule="evenodd" strokeWidth="4px" strokeLinecap="butt" strokeLinejoin="miter"
                d="M49.371,2.000 C43.754,2.000 38.493,4.543 34.990,8.805 C31.490,4.543 26.230,2.000 20.612,2.000 C10.344,2.000 1.993,10.353 1.993,20.621 C1.993,28.660 6.786,37.959 16.241,48.261 C23.518,56.187 31.434,62.324 33.687,64.017 L34.990,64.999 L36.298,64.018 C38.550,62.324 46.465,56.187 53.742,48.261 C63.198,37.960 67.991,28.661 67.991,20.621 C67.991,10.353 59.638,2.000 49.371,2.000 Z" />
        </svg>
      </div>
    );
  }
}

export default Like;
