/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 11.04.2019
* Description: Session actions
* Modification Log:
* License:
*/

import {ACTIONS} from '../../constants/sessions';

export function ActionSessionStart(startValues, remember = false) {
  return {
    remember,
    type: ACTIONS.START,
    data: startValues,
  };
}

export function ActionSessionEnd(endValues) {
  return {
    type: ACTIONS.END,
    data: endValues || {},
  };
}

export function ActionSessionClear() {
  return {
    type: ACTIONS.CLEAR,
  };
}

export function ActionSessionUpdate(namespace, value, remember) {
  const dataToUpdate = {};
  dataToUpdate[namespace] = value;
  return {
    type: ACTIONS.UPDATE,
    data: dataToUpdate,
    remember,
  };
}
