/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Pages actions
* Modification Log:
* License:
*/

import ConstantsStorage from '../../constants/index';

const Constants = ConstantsStorage();

export function ActionPagesUpdateState(namespace, payload) {
  const data = {};

  data[namespace] = payload;

  return {
    type: Constants.FLOWS_ACTIONS.UPDATE,
    data,
  };
}

export function ActionStoreServerData(key, data) {
  return {
    type: Constants.DATA_ACTIONS.UPDATE,
    key,
    data,
  };
}

export function ActionInitialLoading() {
  return (dispatch) => {
    dispatch(ActionStoreServerData('barsCities', null));
  };
}

export function ActionToggleSidebar(state) {
  return (dispatch) => {
    dispatch(ActionPagesUpdateState('sidebar', state));
  };
}
