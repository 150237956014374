/*
* Author: Dima Bozhenko
* Date Created: 08.02.2019
* Date Modified: 14.03.2019
* Description: Bar Detail Location page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _ from 'lodash';
import ROUTES from '../../configs/routes';
import SimplyHeader from '../../components/Header/SimplyHeader';
import Loader from '../../components/Loader/Loader';
import Map from '../../components/Map/Map';
import BarDetailHead from './BarDetailHead';
import {ActionModalMapLink, ActionModalOpenHours} from '../../store/actions/actions-modal';
import './BarDetail.less';

class BarDetailLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barId: parseFloat(this.props.match.params.id),
    };
  }

  onShowOpenHours = (info) => {
    const workHours = {
      Monday: info.Monday,
      Tuesday: info.Tuesday,
      Wednesday: info.Wednesday,
      Thursday: info.Thursday,
      Friday: info.Friday,
      Saturday: info.Saturday,
      Sunday: info.Sunday,
    };

    this.props.ActionModalOpenHours({workHours});
  };

  showMapLinkModal = (e, barInfo) => {
    this.props.ActionModalMapLink({barInfo});
  };

  render() {
    const {isLoading, barDetails, locationBarList} = this.props;
    const barInfo = barDetails[this.state.barId] || {};
    const addr = `${barInfo.addrCity},${barInfo.addrState}`;
    const barOtherInfo = locationBarList[addr] || [];
    const otherInfo = _.find(barOtherInfo, {locationId: this.state.barId}) || {};

    return (
      <div className="app-container">
        <SimplyHeader title="Bar Detail" back={ROUTES.DASHBOARD} />
        <div className="app-content bar-detail">
          <BarDetailHead barId={this.state.barId} currentPage="beerLocation" />
          <div className="-tabs_content">
            <div className="-tabs_location">
              <div className="-info">
                <button className="-info_block loc" onClick={e => this.showMapLinkModal(e, barInfo)}>
                  {barInfo.addrStreet} {barInfo.addrCity}, {barInfo.addrState}, {barInfo.addrZip}
                </button>
                <div className="-info_block tel">
                  <a href={`tel:${barInfo.phone}`}>{barInfo.phone}</a>
                </div>
                <button
                  type="button"
                  className="-info_block time"
                  onClick={() => this.onShowOpenHours(otherInfo)}
                >
                  Open Hours: {barInfo.hours}
                </button>
              </div>
              <Map lat={barInfo.latitude} lng={barInfo.longitude} />
            </div>
          </div>
        </div>
        <Loader isLoading={isLoading} />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionModalMapLink,
    ActionModalOpenHours,
  }, dispatch);
}

function mapStateToProps({rServerData, rPages, rUser}) {
  return {
    rUser,
    barDetails: rServerData.barDetails || {},
    locationBarList: rServerData.locationBarList || {},
    isLoading: rPages.BarDetailPage.isLoading || false,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BarDetailLocation);
