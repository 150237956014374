/*
* Author: Gennady Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Validation utils
* Modification Log:
* License:
*/

export default class ValidationUtils {
  static Form = {
    isEmail: (value) => {
      return /\S+@\S+\.\S+/.test(value);
    },
    isPhoneNumber: (value) => {
      return /^[\+0-9\(\)\s\-]{10,20}$/.test(value);
    },
  };

  static Url = {
    isUrl: (value) => {
      return /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig.test(value);
    },
    isDataURL: (s) => {
      return !!s.match(/^\s*data:([a-z]+\/[a-z0-9\-\+]+(;[a-z\-]+\=[a-z0-9\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i);
    },
  };

  static Device = {
    isMobile: (userAgent) => {
      userAgent = userAgent || navigator.userAgent;
      return /Android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent);
    },
    isMac: (platform) => {
      return (platform || navigator.platform).match(/(Mac|iPhone|iPod|iPad)/i);
    },
  };

  static Browser = {
    // Browser Supports editor functionality
    isValid: () => {
      const div = document.createElement('div');
      const vendors = 'Khtml Ms O Moz Webkit'.split(' ');
      let len = vendors.length;

      return ((prop) => {
        if (prop in div.style) return true;

        prop = prop.replace(/^[a-z]/, (val) => {
          return val.toUpperCase();
        });

        while (len--) {
          if (vendors[len] + prop in div.style) {
            return true;
          }
        }
        return false;
      })('transform');
    },
    // Opera 8.0+
    isOpera: () => {
      return (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    },
    // Firefox 1.0+
    isFirefox: () => {
      return typeof InstallTrigger !== 'undefined';
    },
    // At least Safari 3+: "[object HTMLElementConstructor]"
    isSafari: () => {
      return Object.prototype.toString.call(window.HTMLElement)
        .indexOf('Constructor') > 0;
    },
    // Internet Explorer 6-11
    isIE: () => {
      return !!document.documentMode;
    },
    // Edge 20+
    isEdge: () => {
      return !this.isIE && !!window.StyleMedia;
    },
    // Chrome 1+
    isChrome: () => {
      return !!window.chrome && !!window.chrome.webstore;
    },
    // Blink engine detection
    isBlink: () => {
      return (this.isChrome || this.isOpera) && !!window.CSS;
    },
  };
}
