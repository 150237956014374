/*
* Author: Dima Bozhenko
* Date Created: 15.02.2019
* Date Modified: 15.03.2019
* Description: Modal component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ModalBaseLayout from '../ModalBaseLayout';
import {ActionModalHide} from '../../../store/actions/actions-modal';
import {ActionSignOut} from '../../../store/actions/actions-user';
import {ActionRouteNavigate} from '../../../store/actions/actions-route';

class ModalMapLink extends Component {
  constructor(props) {
    super(props);

    this.settings = ModalBaseLayout.getInitialSettings({
      className: 'modal-center',
      closeButton: true,
      keyboard: true,
    });
  }

  exitClickHandle = () => {
    this.props.ActionModalHide();
  };

  render() {
    const barInfo = this.props.barInfo || {};

    return (
      <ModalBaseLayout {...this.settings}>
        <div className="modal_box modal_map">
          <div className="title">Alert</div>
          <div className="text">Leave MellowMushroom?</div>
          <div className="text">This link will open GoogleMap ?</div>
          <div className="btn_wrap">
            <a
              href={`http://maps.google.com/?q=${barInfo.addrStreet || ''} ${barInfo.addrCity || ''}, ${barInfo.addrState || ''}, ${barInfo.addrZip || ''}`}
              className="btn"
              onClick={this.loginClickHandle}
            >
              Open link
            </a>
          </div>
          <button
            className="modal_close"
            onClick={this.exitClickHandle}
          />
        </div>
      </ModalBaseLayout>
    );
  }
}

ModalMapLink.propTypes = {};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionModalHide,
    ActionSignOut,
    ActionRouteNavigate,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalMapLink);
