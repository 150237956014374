/*
* Author: Dima Bozhenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Common API requests
* Modification Log:
* License:
*/

import $q from 'q';
import _ from 'lodash';
import http from 'services/http';
import UtilsHelper from '../utils/utils-helper';
import ConfigStorage from '../configs/index';
import API_MAP from '../configs/url-api';

const {API_HOST, OTHER_HOST} = ConfigStorage().API_SETTINGS;

export default class Common {
  static getProfileData(userId) {
    const deferred = $q.defer();
    const responseBody = {};
    const body = {};

    http.get(`${API_HOST}${API_MAP.USER_INFO}/${userId}`, body)
      .then((res) => {
        deferred.resolve(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static updateProfileData(data, userId, conceptId) {
    const deferred = $q.defer();
    const responseBody = {
      success: false,
      message: '',
    };
    const reqData = UtilsHelper.serialize({
      conceptId,
    });
    const body = {
      ...data,
    };

    http.put(`${API_HOST}${API_MAP.USER_INFO}/${userId}?${reqData}`, body)
      .then((res) => {
        if (res.Success) {
          responseBody.success = true;
          responseBody.message = res.Message;

          deferred.resolve(res);
        }

        responseBody.message = res.Message;
        deferred.reject(responseBody);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static changePassword(data) {
    const deferred = $q.defer();
    const responseBody = {};

    const body = {
      ...data,
      conceptId: 7,
      beta: 0,
    };

    http.post(`${API_HOST}${API_MAP.CHANGE_PASSWORD}`, body)
      .then((res) => {
        deferred.resolve(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static searchBeer(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const body = {};
    const reqData = UtilsHelper.serialize(data);

    http.get(`${OTHER_HOST}${API_MAP.SEARCH_BEER}?${reqData}`, body)
      .then((res) => {
        deferred.resolve(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static getBeerDetails(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const body = {};
    const reqData = UtilsHelper.serialize(data);

    http.get(`${API_HOST}/user/${data.userId}${API_MAP.BEER_DETAILS}?${reqData}`, body)
      .then((res) => {
        deferred.resolve(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static rateBeer(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const body = {
      ...data,
    };

    http.post(`${API_HOST}/userId/${data.user_id}${API_MAP.RATE_BEER}`, body)
      .then((res) => {
        deferred.resolve(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static getBarList(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const url = _.template(API_MAP.BARS_LIST)({
      userId: data.userId,
      market: data.market,
      conceptId: 7,
    });

    http.get(API_HOST + url)
      .then((res) => {
        if (res.Success) {
          deferred.resolve(res);
        }

        deferred.reject(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static getLocations(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const url = _.template(API_MAP.LOCATIONS)({
      searchField: data.searchField,
      conceptId: 7,
    });

    http.get(OTHER_HOST + url)
      .then((res) => {
        if (res) {
          deferred.resolve(res);
        }

        deferred.reject(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static getBeerList(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const reqData = UtilsHelper.serialize({
      ...data,
    });

    http.get(`${OTHER_HOST}${API_MAP.SEARCH_BEER}?${reqData}`)
      .then((res) => {
        if (res) {
          deferred.resolve(res);
        }

        deferred.reject(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static getBarBeerList(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const url = _.template(API_MAP.DRAFT_MENU)({
      locationId: data.locationId,
      conceptId: 7,
    });

    http.get(OTHER_HOST + url)
      .then((res) => {
        if (res) {
          deferred.resolve(res);
        }

        deferred.reject(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static getUserBeerRating(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const url = _.template(API_MAP.BEER_RATES)({
      beerId: data.beerId,
      conceptId: 7,
      userId: data.userId,
    });

    http.get(API_HOST + url)
      .then((res) => {
        if (res) {
          deferred.resolve(res);
        }
        deferred.reject(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static addToFavorite(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const url = _.template(API_MAP.FAVOURITE)({
      userId: data.user_id,
    });
    const body = {
      ...data,
    };

    http.post(API_HOST + url, body)
      .then((res) => {
        if (res) {
          deferred.resolve(res);
        }
        deferred.reject(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static getAllBarList(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const url = _.template(API_MAP.BARS_LIST)({
      userId: data.userId,
      market: data.market,
      conceptId: 7,
    });

    http.get(API_HOST + url)
      .then((res) => {
        if (res.Success) {
          deferred.resolve(res);
        }

        deferred.reject(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static getLocationBars(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const url = _.template(API_MAP.LOCATION_BARS)({
      userId: data.userId,
      conceptId: 7,
      latitude: data.latitude,
      longitude: data.longitude,
    });

    http.get(API_HOST + url)
      .then((res) => {
        if (res.Success) {
          deferred.resolve(res);
        }

        deferred.reject(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static getBarDetails(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const url = _.template(API_MAP.BAR_DETAILS)({
      locationId: data.locationId,
      userId: data.userId,
      conceptId: 7,
    });

    http.get(API_HOST + url)
      .then((res) => {
        if (res) {
          deferred.resolve(res);
        }

        deferred.reject(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static getUserBeerChipList(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const url = _.template(API_MAP.USER_BEER_CHIP)({
      locationId: data.locationId,
      userId: data.userId,
      conceptId: 7,
    });

    http.get(OTHER_HOST + url)
      .then((res) => {
        if (res) {
          deferred.resolve(res);
        }

        deferred.reject(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static getBeerChipUserAmount(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const url = _.template(API_MAP.USER_BEER_CHIP_AMOUNT)({
      locationId: data.locationId,
      userId: data.userId,
      conceptId: 7,
    });

    http.get(OTHER_HOST + url)
      .then((res) => {
        if (res) {
          deferred.resolve(res);
        }

        deferred.reject(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static getBarInfoByState(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const url = _.template(API_MAP.BAR_DETAILS_BY_LOCATION)({
      searchMarketCity: data.city,
      searchMarketState: data.state,
      userId: data.userId,
      conceptId: 7,
    });

    http.get(API_HOST + url)
      .then((res) => {
        if (res) {
          deferred.resolve(res);
        }

        deferred.reject(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static collectBeerchip(data) {
    const deferred = $q.defer();
    const responseBody = {};

    http.post(`${OTHER_HOST}${API_MAP.COLLECT_BEERCHIP}`, data)
      .then((res) => {
        if (res) {
          deferred.resolve(res);
        }

        deferred.reject(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }
}
