/*
* Author: Dima Bozhenko
* Date Created: 24.01.2019
* Date Modified: 14.03.2019
* Description: Favorite API requests
* Modification Log:
* License:
*/

import $q from 'q';
import _ from 'lodash';
import http from 'services/http';
import UtilsHelper from '../utils/utils-helper';
import ConfigStorage from '../configs/index';
import API_MAP from '../configs/url-api';

const {API_HOST, OTHER_HOST} = ConfigStorage().API_SETTINGS;

export default class Favorite {
  static favoriteBeers(user) {
    const deferred = $q.defer();
    const responseBody = {};
    const userId = user.id;
    const body = {};
    let requestData = {
      userId,
      clientId: user.clientId,
    };
    requestData = UtilsHelper.serialize(requestData);

    http.get(`${API_HOST}/user/${userId}${API_MAP.FAVORITE_BEERS}?${requestData}`, body)
      .then((res) => {
        deferred.resolve(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static favoriteBars(user) {
    const deferred = $q.defer();
    const responseBody = {};
    const userId = user.id;
    const body = {};
    let requestData = {
      userId,
      conceptId: 7,
      latitude: user.latitude,
      longitude: user.longitude,
    };
    requestData = UtilsHelper.serialize(requestData);

    http.get(`${API_HOST}/user/${userId}${API_MAP.FAVORITE_BARS}?${requestData}`, body)
      .then((res) => {
        deferred.resolve(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static favoriteStyles(user) {
    const deferred = $q.defer();
    const responseBody = {};
    const body = {};
    const userId = user.id;
    let requestData = {
      userId,
      clientId: user.clientId,
    };
    requestData = UtilsHelper.serialize(requestData);

    http.get(`${OTHER_HOST}${API_MAP.FAVORITE_STYLES_GET}?${requestData}`, body)
      .then((res) => {
        deferred.resolve(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static changeFavoriteStyles(data) {
    const deferred = $q.defer();
    const responseBody = {};
    const url = _.template(API_MAP.FAVORITE_STYLES_CHANGE)({
      userId: data.bm_user_id,
    });

    http.post(API_HOST + url, data)
      .then((res) => {
        deferred.resolve(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }
}
