/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 06.05.2019
* Description: Constants
* Modification Log:
* License:
*/

export const ACTIONS = {
  UPDATE: 'SERVER_DATA_UPDATE',
  CLEAR: 'SERVER_DATA_CLEAR',
};

export const STORAGE_SERVER_DATA_NAMESPACE = 'local_server_data_space';
