/*
* Author: Dima Bozhenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Routes
* Modification Log:
* License:
*/

import URL_MAP from '../constants/urls';

const BASE_URL = '';

function buildRoute() {
  let route = '';
  const size = arguments.length;

  for (let i = 0; i < size; i++) {
    const argument = arguments[i] + '';
    const isOptional = argument.indexOf('(') !== -1;
    route += !isOptional ? '/' : '';
    route += argument;
  }

  return route;
}

export {buildRoute};

const ROUTES = {
  ROOT: '/' + BASE_URL,
  EXAMPLE: buildRoute(URL_MAP.EXAMPLE),
  AGE_CONFIRM: buildRoute(URL_MAP.AGE_CONFIRM),
  LOGIN: buildRoute(URL_MAP.LOGIN),
  WELCOME: buildRoute(URL_MAP.WELCOME),
  SIGNUP: buildRoute(URL_MAP.SIGNUP),
  FORGOT_PASSWORD: buildRoute(URL_MAP.FORGOT_PASSWORD),
  RESET_PASSWORD: buildRoute(URL_MAP.RESET_PASSWORD),
  DASHBOARD: buildRoute(URL_MAP.DASHBOARD),
  PROFILE: buildRoute(URL_MAP.PROFILE),
  PROFILE_CHANGE_PASSWORD: buildRoute(URL_MAP.PROFILE, URL_MAP.CHANGE_PASSWORD),
  FAVORITES_BARS: buildRoute(URL_MAP.FAVORITES, URL_MAP.BARS),
  FAVORITES_BEERS: buildRoute(URL_MAP.FAVORITES, URL_MAP.BEERS),
  FAVORITES_STYLES: buildRoute(URL_MAP.FAVORITES, URL_MAP.STYLES),
  SUPPORT_FAQ: buildRoute(URL_MAP.SUPPORT, URL_MAP.FAQ),
  SUPPORT_PRIVACY: buildRoute(URL_MAP.SUPPORT, URL_MAP.PRIVACY),
  SUPPORT_TERMS: buildRoute(URL_MAP.SUPPORT, URL_MAP.TERMS),
  BAR_DETAIL_BEER_LIST: buildRoute(URL_MAP.BAR_DETAIL, URL_MAP.BEER_LIST, ':id'),
  BAR_DETAIL_BEER_CLUB: buildRoute(URL_MAP.BAR_DETAIL, URL_MAP.BEER_CLUB, ':id'),
  BAR_DETAIL_LOCATION: buildRoute(URL_MAP.BAR_DETAIL, URL_MAP.LOCATION, ':id'),
  BEER_DETAIL: buildRoute(URL_MAP.BEER_DETAIL, ':id'),
};

export default ROUTES;
