/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 15.03.2019
* Description: RooteLayout component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import Modals from '../Modal/Modal';
import Sidebar from '../Sidebar/Sidebar';
import {ActionInitialLoading} from '../../store/actions/actions-pages';

class RootLayout extends Component {
  componentWillMount() {
    this.props.ActionInitialLoading();
  }

  render() {
    const showSidebar = this.props.rPages.sidebar;
    return (
      <div id="outer-container">
        <Sidebar />
        <div id="main-wrapper" className={`main-wrapper ${showSidebar ? 'fade' : ''}`}>
          {this.props.children}
        </div>
        <ToastContainer />
        <Modals />
      </div>
    );
  }
}

RootLayout.propTypes = {
  ActionInitialLoading: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionInitialLoading,
  }, dispatch);
}

function mapStateToProps({rPages}) {
  return {
    rPages,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RootLayout);
