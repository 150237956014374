/*
* Author: Gennadiy Yatsenko
* Date Created: 04.02.2019
* Date Modified: 15.03.2019
* Description: Support Privacy page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ROUTES from '../../configs/routes';
import Header from '../../components/Header/Header';
import NavBar from '../../components/NavBar/NavBar';

class SupportPrivacy extends Component {
  render() {
    const tabsData = [
      {
        route: ROUTES.SUPPORT_FAQ,
        name: 'FAQ\'S',
      },
      {
        route: ROUTES.SUPPORT_PRIVACY,
        name: 'PRIVACY',
      },
      {
        route: ROUTES.SUPPORT_TERMS,
        name: 'TERMS',
      },
    ];

    return (
      <div className="app-container">
        <div className="app-content favorites">
          <Header logo />
          <div className="favorites_head">
            <NavBar data={tabsData} activeIndex={1} />
          </div>
          <div className="favorites_content">
            <div className="-tabs_text">
              <h2>USBN PRIVACY POLICY</h2>
              <h3>INTERNET</h3>
              <p>
                Introduction: This website is owned and run by USBeveragenet.USBN is committed to protecting and safeguarding your Privacy. We promise to protect your privacy and information in line with this privacy policy and the Data Protection Act.
                <br />
                Marketing: You’re in controlWe appreciate that you do not want to receive junk mail.The data you give us about yourself will enable USBN and other companies within the USBN group of companies to send you updates and provide you with services you have requested. We may use your e-mail address to contact you.We will not share your information with any other company.At any time, you can let us know if you do not want to receive any information.
              </p>
              <p>Equally, you can amend data that you have previously submitted to USBN.</p>
              <p>We use website links to improve our site. These links are clearly identified to make you aware that you are leaving our website. Please be mindful of the fact that these websites are not owned and maintained by USBN. They are run by other companies and organizations and we can therefore not accept any responsibility for any personal information you choose to divulge to them. They will adhere to their own privacy policies.</p>
              <p>What information do we hold and where do we get it from?Normally, we receive information about you in two ways:Directly from youFor instance when you register your details with us, we record the information you provide us with.Indirectly from you</p>
              <p>For example, information whilst visiting our website (see section 4, Cookies) or USBN company websites, or details relating to transactions you enter into with us.</p>
              <p>
                CookiesCookies are small pieces of information that can be stored on your computer when you use a website. We use cookies on the internet to process and identify data. The cookie is transferred to your hard drive for recording purposes, ‘reminding’ us of where you have been on our website.This helps to save you time and direct you to the most relevant parts of the site. If you want, you can set your browser to refuse cookies.We try to ensure that our website offers you exactly what you are looking for. For USBN to achieve this goal, we may analyse anonymous data collected by cookies about how you use the site. This data also helps us to measure the success of our advertising campaigns.USBN may also collect Internet Protocol addresses to help identify and correct problems with our servers and for systems administration. We do not line these addresses to any individual’s name or identity.
                <br />
                This ServiceWe will use your information in several ways to make sure that this service you asked for is provided as efficiently as possible. This includes:Ensuring that this service we offer continues to best suit your needs.
              </p>
              <p>Responding to any query that you may raise with us about your account and to help us in future dealings with you.</p>
              <p>Updating our systems to enhance this service that we provide to you.</p>
              <p>Enabling third parties to process and manage your information to enable us to provide this service to you.</p>
              <p>How else do we use your details?We will also use your information for purposes other than providing you with services. This includes:</p>
              <p>For security, administrative and legal purposes.</p>
              <p>Will we ever change this policy?Our privacy policy sets out what happens to your personal information. Because we are constantly updating and reviewing our service to you, there may be developments in how we use your data.We will promptly notify you of any changes with a link to this Privacy Policy on the log-in page of this site. / We may change your privacy policy and therefore encourage you to come back to this page occasionally / encourage you to check our website under ‘Privacy Policy’ occasionally.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportPrivacy);
