/*
* Author: Gennady Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Utils
* Modification Log:
* License:
*/

import StorageApi from '../api/storage';
import {VERSION} from '../configs';
import {STORAGE_USER_NAMESPACE} from '../constants/users';
import {STORAGE_SESSION_NAMESPACE} from '../constants/sessions';

class UtilsHelper {
  static serialize(object, prefix) {
    const str = [];

    for (const prop in object) {
      if (object.hasOwnProperty(prop)) {
        const key = prefix ? prefix + '[' + prop + ']' : prop;
        const value = object[prop];

        str.push((value !== null && typeof v === 'object')
          ? UtilsHelper.serialize(value, key)
          : encodeURIComponent(key) + '=' + encodeURIComponent(value));
      }
    }

    return str.join('&');
  }

  static randomStr(length = 7) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  static setAppVersion() {
    const currentVersion = StorageApi.local.get('VERSION');

    if (!currentVersion || VERSION !== currentVersion) {
      StorageApi.delete(STORAGE_USER_NAMESPACE);
      StorageApi.delete(STORAGE_SESSION_NAMESPACE);
    }

    StorageApi.local.set('VERSION', VERSION);
  }
}

export default UtilsHelper;
