/*
* Author: Dima Bozhenko
* Date Created: 04.02.2019
* Date Modified: 11.04.2019
* Description: Login page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {createForm} from 'rc-form';
import _ from 'lodash';
import Loader from '../../components/Loader/Loader';
import ROUTES from '../../configs/routes';
import FacebookLoginBtn from '../../components/Facebook/FacebookLoginBtn';
import {ActionLogin, ActionGuestLogin} from '../../store/actions/actions-user';
import './Login.less';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rememberMe: !_.isEmpty(props.loginData),
    };
  }

  loginClickHandle = (e) => {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        this.props.ActionLogin(values.Email, values.Password, this.state.rememberMe);
      }
    });
  };

  guestClickHandle = (e) => {
    e.preventDefault();
    this.props.ActionGuestLogin();
  };

  inputChangeHandle = (value, type) => {
    this.setState({
      [type]: value,
    });
  };

  render() {
    const {isLoading, loginData} = this.props;
    const {rememberMe} = this.state;
    let errors;
    const {getFieldProps, getFieldError} = this.props.form;

    return (
      <div className="app-container">
        <div className="login">
          <div className="-head">
            <div className="-logo" />
            <div className="-powered">
              <span>Powered By</span>
              <div className="-beerboard" />
            </div>
          </div>
          <div className="-content">
            <div className="-title" style={{marginBottom: '40px'}}>Log In</div>
            <div className="-field -field--user">
              <input
                type="email"
                placeholder="Email-Id"
                {...getFieldProps('Email', {
                  initialValue: loginData.email || '',
                  rules: [{
                    required: true,
                  }],
                })}
              />
              {(errors = getFieldError('Email')) ?
                <div className="-field_error">{errors.join(',')}</div> : null}
            </div>
            <div className="-field -field--password">
              <input
                type="password"
                placeholder="Password"
                {...getFieldProps('Password', {
                  initialValue: loginData.password || '',
                  rules: [{
                    required: true,
                  }],
                })}
              />
              {(errors = getFieldError('Password')) ?
                <div className="-field_error">{errors.join(',')}</div> : null}
            </div>
            <div className="-field -field--other">
              <label htmlFor="remember" className="checkbox">
                <input
                  type="checkbox"
                  id="remember"
                  checked={rememberMe}
                  onChange={e => this.inputChangeHandle(e.target.checked, 'rememberMe')}
                />
                <span className="-icon" />
                <span className="-text">Remember me</span>
              </label>
              <Link className="-link" to={ROUTES.FORGOT_PASSWORD}>Forgot Password?</Link>
            </div>
            <div className="-btns_field">
              <button
                className="-btn -btn--login"
                onClick={this.loginClickHandle}
              >
                Login
              </button>
              <div className="-divider">or</div>
              <FacebookLoginBtn />
            </div>
            <div className="-text">
              Are you a new user? &nbsp;
              <Link to={ROUTES.SIGNUP}>Signup Now </Link>
            </div>
            <div className="-text">
              Want to try the Application? &nbsp;
              <button className="guest" onClick={this.guestClickHandle}>Be a Guest</button>
            </div>
          </div>
        </div>
        <Loader isLoading={isLoading} />
      </div>
    );
  }
}

Login.propTypes = {};

Login.defaultProps = {};

function mapStateToProps({rLoginPage, rPages, rSession}) {
  return {
    rLoginPage,
    isLoading: rPages.LoginPage.isLoading || false,
    loginData: rSession.loginData || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionLogin,
    ActionGuestLogin,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(createForm()(Login));
