/*
* Author: Dima Bozhenko
* Date Created: 07.02.2019
* Date Modified: 15.03.2019
* Description: Modal component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ROUTES from '../../../configs/routes';
import ModalBaseLayout from '../ModalBaseLayout';
import {ActionModalHide} from '../../../store/actions/actions-modal';
import {ActionSignOut} from '../../../store/actions/actions-user';
import {ActionRouteNavigate} from '../../../store/actions/actions-route';

class ModalLogin extends Component {
  constructor(props) {
    super(props);

    this.settings = ModalBaseLayout.getInitialSettings({
      className: 'modal-center',
      closeButton: true,
      keyboard: true,
    });
  }

  exitClickHandle = () => {
    this.props.ActionModalHide();
  };


  loginClickHandle = () => {
    this.props.ActionSignOut();
    this.props.ActionModalHide();
    this.props.ActionRouteNavigate(ROUTES.LOGIN);
  };

  signupClickHandle = () => {
    this.props.ActionSignOut();
    this.props.ActionModalHide();
    this.props.ActionRouteNavigate(ROUTES.SIGNUP);
  };

  render() {
    return (
      <ModalBaseLayout {...this.settings}>
        <div className="modal_box modal_login">
          <div className="text">You must Login to view this screen</div>
          <div className="btn_wrap">
            <button
              className="btn"
              onClick={this.loginClickHandle}
            >
              Login
            </button>
          </div>
          <div className="text">Are you New User?</div>
          <div className="btn_wrap">
            <button
              className="btn"
              onClick={this.signupClickHandle}
            >
              Sign up
            </button>
          </div>
          <button
            className="modal_close"
            onClick={this.exitClickHandle}
          />
        </div>
      </ModalBaseLayout>
    );
  }
}

ModalLogin.propTypes = {};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionModalHide,
    ActionSignOut,
    ActionRouteNavigate,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalLogin);
