/*
* Author: Dima Bozhenko
* Date Created: 20.02.2019
* Date Modified: 03.05.2019
* Description: Modal component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ModalBaseLayout from '../ModalBaseLayout';
import {ActionModalHide} from '../../../store/actions/actions-modal';
import {ActionRouteNavigate} from '../../../store/actions/actions-route';

class ModalAgeConfirmNotSuccess extends Component {
  constructor(props) {
    super(props);

    this.settings = ModalBaseLayout.getInitialSettings({
      className: 'modal-center',
      closeButton: true,
      keyboard: true,
    });
  }

  exitClickHandle = () => {
    this.props.ActionModalHide();
  };

  render() {
    return (
      <ModalBaseLayout {...this.settings}>
        <div className="modal_box modal_notificate">
          <div className="icon icon--stop" />
          <div className="text">Sorry! You are not eligible to access the application</div>
          <div className="btn_wrap">
            <button
              className="btn"
              onClick={this.exitClickHandle}
            >
              Exit
            </button>
          </div>
        </div>
      </ModalBaseLayout>
    );
  }
}

ModalAgeConfirmNotSuccess.propTypes = {};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionRouteNavigate,
    ActionModalHide,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAgeConfirmNotSuccess);
