/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Viewport reducer
* Modification Log:
* License:
*/

import ConstantsStorage from '../../constants/index';
import {initialViewportModel} from '../models/viewport-model';

let ACTIONS = ConstantsStorage().VIEWPORT_ACTIONS;

export default (state = initialViewportModel(), action) => {
  switch (action.type) {
  case ACTIONS.TOGGLE_MAIN_MENU:
    return {...state, show_main_menu: action.show_menu};

  default:
    return state;
  }
};
