/*
* Author: Gennadiy Yatsenko
* Date Created: 04.02.2019
* Date Modified: 15.03.2019
* Description: Support FAQ page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ROUTES from '../../configs/routes';
import Header from '../../components/Header/Header';
import NavBar from '../../components/NavBar/NavBar';

class SupportTerms extends Component {
  render() {
    const tabsData = [
      {
        route: ROUTES.SUPPORT_FAQ,
        name: 'FAQ\'S',
      },
      {
        route: ROUTES.SUPPORT_PRIVACY,
        name: 'PRIVACY',
      },
      {
        route: ROUTES.SUPPORT_TERMS,
        name: 'TERMS',
      },
    ];

    return (
      <div className="app-container">
        <div className="app-content favorites">
          <Header logo />
          <div className="favorites_head">
            <NavBar data={tabsData} activeIndex={2} />
          </div>
          <div className="favorites_content">
            <div className="-tabs_text">
              <h2>USBN TERMS AND CONDITIONS</h2>
              <h3>BY ACCESSING, BROWSING AND/OR USING USBEVERAGENET.COM YOU AGREE TO THE FOLLOWING TERMS.</h3>
              <p>
                USBN and any other USBN product or service name referred to on the USBeveragenet.com website are trade marks of USBeveragenet. <br/>
                Content (information, communications, images and sounds contained on or available through USBeveragenet.com) is provided by USBN, its affiliates, independent content providers and third parties. The contents of USBeveragenet.com are copyright of USBeveragenet. All rights reserved. The contents of USBeveragenet.com cannot be reproduced, modified, transferred, distributed, republished, downloaded, posted or transmitted in any form or by any means including but not limited to electronic, mechanical photocopying or recording without the prior written permission of USBeveragenet. You agree that the material and content contained within or provided by USBeveragenet.com is for your own personal use only and may not be used for commercial purposes or distributed commercially.<br/>
                USBN will use its reasonable endeavours to maintain USBeveragenet.com in a fully operating condition. It is not responsible for the results of any defects that exist in USBeveragenet.com You should not assume that USBeveragenet.com or its content is error free or that it will be suitable for the particular purposes that you have in mind when using it. USBN reserves the right to make subsequent changes to it, and services may be modified, supplemented or withdrawn. <br/>
                It is a condition of us allowing you access to the information on USBeveragenet.com that you accept we will not be liable for any action you take relying on the information on USBeveragenet.com USBN will not be liable if your use of materials or information from USBeveragenet.com results in the need for servicing, repair or correction of equipment or data, you assume any costs relating to the above. USBN reserves the right to charge for access to certain information on USBeveragenet.com USBN will inform you where a charge will be incurred by you for accessing certain services or information on USBeveragenet.com <br/>
                If your PC does not support relevant technology including but not limited to encryption you may not be able to use certain
              </p>
              <br />
              <p>
                <b>Disclaimers:</b>
              </p>
              <p>
                <b>BeerBoard Disclaimer and Waiver of Liability:</b>
              </p>
              <p>
                By accessing or using the Services provided by or on behalf of BeerBoard in any manner, you represent and warrant that you are of a legal age to consume alcohol and you agree that you use our Services at your own risk. You further acknowledge that any incentive, rebate, promotion or offer that you receive by or through the Services is intended for your personal consumption and will not be resold or transferred to any third party.
              </p>
              <p>
                In connection with providing the Services, BeerBoard and its third party brewery partners may make rebate, promotional or other offers available to you (each an "Offer"). You acknowledge and agree that any such Offer that you receive or accept is independent, not from any individual retailer, and that your consumption of any alcohol is a personal decision that you agree to make in compliance with all applicable laws and regulations and in consultation with a healthcare professional. You acknowledge that any Offer may more specifically outline the terms and conditions that apply between you and the offering brewery for the appropriate purchase of the applicable beverage product and that BeerBoard will not provide any portion of such rebate funds to the individual retailer. The Services are provided by BeerBoard on an "AS IS" and "AS AVAILABLE" basis. BeerBoard makes no representations or warranties of any kind, express or implied, as to the operation of the Services, or the information, content, materials, products or offerings available via the Services. To the fullest extent permissible by applicable law, BeerBoard hereby disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. BeerBoard shall, under no circumstances, be liable to you and/or any third party for any damages of any kind arising from the use of the Services, including, but not limited to, direct, indirect, special, punitive or consequential damages, even if BeerBoard has been advised of the possibility of such damages. In all events, BeerBoard's maximum liability is limited to a refund of the total purchase price that you paid for any product or service.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportTerms);
