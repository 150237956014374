/*
* Author: Dima Bozhenko
* Date Created: 06.02.2019
* Date Modified: 12.04.2019
* Description: Facebook login component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import FacebookLogin from 'react-facebook-login';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ActionFacebookLogin} from '../../store/actions/actions-user';
import ConfigStorage from '../../configs';

const CONFIG_STORAGE = ConfigStorage();

class FacebookLoginBtn extends Component {

  responseFacebook = (response) => {
    if (response.email) {
      this.props.ActionFacebookLogin(response);
    }
  };

  render() {
    return (
      <FacebookLogin
        appId={CONFIG_STORAGE.API_SETTINGS.FACEBOOK_API_KEY}
        disableMobileRedirect
        cookie
        fields="name,email,picture"
        xfbml
        version="3.2"
        onClick={this.onClickHandle}
        callback={this.responseFacebook}
        cssClass="-btn -btn--fb"
      />
    );
  }
}

function mapStateToProps({rSession}) {
  return {
    rSession,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionFacebookLogin,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FacebookLoginBtn);
