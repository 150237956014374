/*
* Author: Dima Bozhenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: URLS
* Modification Log:
* License:
*/

const BETA = 0;
const conceptId = 7;

const API_URL_MAP = {
  EXAMPLE_URL: '',
  SIGN_IN: '/authenticate',
  SIGN_UP: '/signup',
  FORGOT_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/resetpassword',
  USER_INFO: '/userinfo',
  CHANGE_PASSWORD: '/changepassword',
  FAVORITE_STYLES_GET: '/getBeerBoardStylesNew',
  FAVORITE_STYLES_CHANGE: '/userId/${userId}/favouritestyle?beta=' + BETA + '/userId/${userId}',
  FAVORITE_BARS: '/favouritebars',
  FAVORITE_BEERS: '/favouritebeers',
  SEARCH_BEER: '/getBeerBoardSearchDatanew',
  RATE_BEER: '/ratebeer',
  BEER_DETAILS: '/beerDetails',
  BARS_LIST: '/user/${userId}/bars?&searchMarket=${market}&conceptId=' + conceptId + '&beta=' + BETA,
  LOCATIONS: '/getBeerBoardMarkets?&searchField=${searchField}&conceptId=' + conceptId + '&beta=' + BETA,
  BEER_LIST: '/getBeerBoardSearchDatanew?searchField=${beerSearch}&userId=${userId}&searchType=${searchType}&searchMarket=${searchMarket}&q=${beerSearch}&conceptId=' + conceptId + '&beta=' + BETA,
  DRAFT_MENU: '/draftMenu?location_id=${locationId}&conceptId=' + conceptId + '',
  BEER_RATES: '/user/${userId}/beer/${beerId}/beerrates?beta=0&conceptId=' + conceptId + '&userId=${userId}',
  FAVOURITE: '/userId/${userId}/favourite?beta=0/userId/${userId}',
  FACEBOOK: '/facebook',
  LOCATION_BARS: '/user/0/bars?latitude=${latitude}&longitude=${longitude}&userId=${userId}&conceptId=' + conceptId + '&beta=' + BETA,
  BAR_DETAILS: '/user/${userId}/barslocation?locationId=${locationId}&userId=${userId}&conceptId=' + conceptId + '&beta=' + BETA,
  USER_BEER_CHIP: '/getUserBeerChip?conceptId=' + conceptId + '&userId=${userId}&locationId=${locationId}&beta=' + BETA,
  USER_BEER_CHIP_AMOUNT: '/getBMUserAmount?conceptId=' + conceptId + '&userId=${userId}&locationId=${locationId}&beta=' + BETA,
  BAR_DETAILS_BY_LOCATION: '/user/${userId}/bars?searchMarket=${searchMarketCity},${searchMarketState}&userId=${userId}&conceptId=' + conceptId + '&beta=' + BETA,
  COLLECT_BEERCHIP: '/verifyBeerChipCheckID',
};

export default API_URL_MAP;
