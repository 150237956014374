/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Http service
* Modification Log:
* License:
*/

import 'isomorphic-fetch';
import $q from 'q';
import _ from 'lodash';
import UtilsHelper from '../utils/utils-helper';
import ResponseManager from './responseManager';

class request {
  static buildUri(obj) {
    return '?' + UtilsHelper.serialize(obj);
  }

  static HEADERS = {};

  static setHeader(header) {
    request.HEADERS = {...request.HEADERS, ...header};
    return request;
  }

  static removeHeader(headerKey) {
    if (_.has(request.HEADERS, headerKey)) {
      delete request.HEADERS[headerKey];
    }
    return request;
  }
}

class response {
  static parse(res, type = 'json') {
    ResponseManager.receive(res);
    switch (type) {
    case 'json':
      return res.json();
    case 'text':
    default:
      return res.text();
    }
  }

  static hasError(res) {
    return _.has(res, 'errorObj');
  }
}

class http {
  static DEFAULT_HEADERS = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  static prepareBody(body) {
    return _.isObject(body) ? JSON.stringify(body) : body;
  }

  static prepareHeaders(headers, useDefaultHeaders = true) {
    let buildHeaders = useDefaultHeaders ? http.DEFAULT_HEADERS : {};

    if (!_.isEmpty(http.request.HEADERS)) {
      buildHeaders = {...buildHeaders, ...http.request.HEADERS};
    }

    if (!headers || !_.isObject(headers)) {
      return buildHeaders;
    }

    return {...buildHeaders, ...headers};
  }

  static requestBuilder(methodType, url, body, headers = {}, isNoCors = false) {
    const deferred = $q.defer();
    const params = {
      method: methodType,
      headers: http.prepareHeaders(headers),
      body: http.prepareBody(body),
    };

    if (isNoCors) {
      params.mode = 'no-cors';
    }

    fetch(url, params)
      .then(res => response.parse(res))
      .then((json) => {
        deferred.resolve(json);
      })
      .catch((ex) => {
        deferred.reject(ex);
      });

    return deferred.promise;
  }

  static post(url, body, headers = {}, isNoCors = false) {
    return http.requestBuilder('POST', url, body, headers, isNoCors);
  }

  static req(methodType, url, body, headers = {}, isNoCors = false) {
    const deferred = $q.defer();
    const params = {
      method: methodType,
      headers: http.prepareHeaders(headers, false),
      body: http.prepareBody(body),
    };

    if (isNoCors) {
      params.mode = 'no-cors';
    }

    fetch(url, params)
      .then(res => response.parse(res))
      .then((json) => {
        deferred.resolve(json);
      })
      .catch((ex) => {
        deferred.reject(ex);
      });

    return deferred.promise;
  }

  static put(url, body, headers = {}, isNoCors = false) {
    return http.requestBuilder('PUT', url, body, headers, isNoCors);
  }

  static get(url, headers = {}, isNoCors = false) {
    const deferred = $q.defer();
    const params = {
      headers: http.prepareHeaders(headers),
    };

    if (isNoCors) {
      params.mode = 'no-cors';
    }

    fetch(url, params)
      .then(res => response.parse(res))
      .then((json) => {
        deferred.resolve(json);
      })
      .catch((ex) => {
        deferred.reject(ex);
      });

    return deferred.promise;
  }
}

http.response = response;
http.request = request;

export default http;
