/*
* Author: Dima Bozhenko
* Date Created: 04.02.2019
* Date Modified: 09.04.2019
* Description: Age confirm page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ROUTES from 'configs/routes';
import {ActionRouteNavigate} from 'store/actions/actions-route';
import {ActionModalShowAgeConfirmNotSuccess} from 'store/actions/actions-modal';
import './Login.less';

class AgeConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: '',
    };
  }

  submitClickHandle = (e) => {
    e.preventDefault();
    const year = parseFloat(this.state.year);
    const currentYear = (new Date()).getFullYear();
    const agreeYear = currentYear - 21;

    if (agreeYear >= year && year >= 1900) {
      this.props.ActionRouteNavigate(ROUTES.LOGIN);
    } else {
      this.props.ActionModalShowAgeConfirmNotSuccess();
    }
  };

  inputChangeHandle = (value, type) => {
    this.setState({
      [type]: value.slice(0, 4),
    });
  };

  render() {
    const {year} = this.state;

    return (
      <div className="app-container">
        <div className="login">
          <div className="-head">
            <div className="-logo" />
            <div className="-powered">
              <span>Powered By</span>
              <div className="-beerboard" />
            </div>
          </div>
          <div className="-content">
            <div className="-title" style={{marginBottom: '40px'}}>Age confirmation</div>
            <div className="-field -field--year">
              <input
                type="text"
                inputMode="numeric"
                pattern="\d*"
                placeholder="Year of Birth"
                required
                value={year}
                onChange={e => this.inputChangeHandle(e.target.value, 'year')}
              />
            </div>
            <div className="-btns_field">
              <button
                className="-btn -btn--login"
                onClick={this.submitClickHandle}
                disabled={!(year.length === 4)}
              >
                Submit
              </button>
            </div>
            <div className="-text -text--orange">
              I`M UNDER LEGAL DRINKING AGE
            </div>
            <div className="-disclaimer">
              <div className="-disclaimer_title">
                DISCLAIMER:
              </div>
              Only guests over the age of 21 can use this Application
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AgeConfirm.propTypes = {};

AgeConfirm.defaultProps = {};

function mapStateToProps({rLoginPage}) {
  return {rLoginPage};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionRouteNavigate,
    ActionModalShowAgeConfirmNotSuccess,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AgeConfirm);
