/*
* Author: Dima Bozhenko
* Date Created: 04.02.2019
* Date Modified: 15.03.2019
* Description: NavBar component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

class NavBar extends Component {
  render() {
    const {activeIndex, data} = this.props;

    return (
      <div className="-tabs">
        <div className="-tabs_nav">
          {
            data.map((item, index) => {
              return (
                <Link
                  key={index}
                  to={item.route}
                  className={`${activeIndex === index ? 'active' : ''}`}>
                  {item.name}
                </Link>
              );
            })
          }
        </div>
      </div>
    );
  }
}

NavBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeIndex: PropTypes.number.isRequired,
};

export default NavBar;
