/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 09.04.2019
* Description: Config
* Modification Log:
* License:
*/

import ROUTES from './routes';
import METADATA from './metadata';

const VERSION = '1.0.0';

export {VERSION};

const currentEnvironment = process.env.NODE_ENV;
let currentConfiguration;

if (currentEnvironment === 'development') {
  currentConfiguration = {
    API_HOST: 'https://api.menu.beerboard.com',
    OTHER_HOST: 'https://api.beerboard.com/nodejsserver',
    GOOGLE_MAPS_API_KEY: 'AIzaSyA-ZCHGnqDPnEFYrKKRK-WjRyWBc8yT94Q',
    FACEBOOK_API_KEY: '483078498868785',
  };
} else {
  currentConfiguration = {
    API_HOST: 'https://api.menu.beerboard.com',
    OTHER_HOST: 'https://api.beerboard.com/nodejsserver',
    GOOGLE_MAPS_API_KEY: 'AIzaSyA-ZCHGnqDPnEFYrKKRK-WjRyWBc8yT94Q',
    FACEBOOK_API_KEY: '483078498868785',
  };
}

const API_SETTINGS = currentConfiguration;

const CACHE_LIFETIME = {
  duration: 14,
  units: 'days',
};

export default function ConfigStorage() {
  return {
    ROUTES,
    METADATA,
    API_SETTINGS,
    CACHE_LIFETIME,
  };
}
