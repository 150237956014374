/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Config
* Modification Log:
* License:
*/

import ConstantsStorage from '../constants/index';
import ROUTES from '../configs/routes';

const METADATA = {};
const URLS = ConstantsStorage().URL;
const URL_SUFFIX = ' - Site name';

function trimSlashes(url) {
  return url && url.replace(/^\/|\/$/g, '');
}

function getMetadata(url) {
  const trimmedUrl = trimSlashes(url);

  return trimmedUrl && METADATA[trimmedUrl] ? METADATA[trimmedUrl] : {};
}

// METADATA[trimSlashes(ROUTES.REMIND_PASSWORD)] = {
//     title: 'Remind password' + URL_SUFFIX,
//     description: 'BeerBoard User Remind Password'
// };

export default METADATA;

export {getMetadata};
