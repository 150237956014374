/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Modal model
* Modification Log:
* License:
*/

function initialModalModel() {
  return {
    templateType: '',
    data: {},
  };
}

export {initialModalModel};
