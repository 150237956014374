/*
* Author: Dima Bozhenko
* Date Created: 08.02.2019
* Date Modified: 14.03.2019
* Description: Bar Detail Head component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {buildRoute} from '../../configs/routes';
import Like from '../../components/UI/Like/Like';
import URLS from '../../constants/urls';
import {
  ActionGetBarDetails,
  ActionAddToFavoriteBar,
  ActionGetBarInfoByState,
} from '../../store/actions/actions-request';
import {ActionModalShowLogin} from '../../store/actions/actions-modal';

class BarDetailHead extends Component {
  componentDidMount() {
    const {barId} = this.props;

    if (!this.props.barDetails[barId]) {
      this.props.ActionGetBarDetails(barId, true);
    }
  }

  linkClickHandle = (e) => {
    if (this.props.userId === 0) {
      e.preventDefault();
      this.props.ActionModalShowLogin();
      return null;
    }
  };

  likeClickHandle = (value) => {
    if (this.props.userId === 0) {
      this.props.ActionModalShowLogin();
      return null;
    }

    const isFavorite = value === 1 ? 0 : 1;

    this.props.ActionAddToFavoriteBar(this.props.barId, isFavorite);
  };

  render() {
    const {currentPage, barId, userId} = this.props;
    const barDetails = this.props.barDetails || {};
    const barInfo = barDetails[barId] || {};

    return (
      <div className="bar-detail_head">
        <div className="-loc" />
        <div className="-info">
          <div className="-title">{barInfo.boardname}</div>
          <div className="-addr">{barInfo.addrStreet} <br/> {barInfo.addrCity}, {barInfo.addrState}, {barInfo.addrZip}</div>
          <Like onClick={() => this.likeClickHandle(barInfo.isFavorite)} value={barInfo.isFavorite} />
        </div>
        <div className="-tabs">
          <ul className="-tabs_nav">
            <Link className={`${currentPage === 'beerList' ? 'active' : ''}`} to={buildRoute(URLS.BAR_DETAIL, URLS.BEER_LIST, barId)}>Menu</Link>
            <Link className={`${currentPage === 'beerClub' ? 'active' : ''}`} onClick={this.linkClickHandle} to={buildRoute(URLS.BAR_DETAIL, URLS.BEER_CLUB, barId)}>{userId === 0 ? 'Join Club' : 'Beer Club'}</Link>
            <Link className={`${currentPage === 'beerLocation' ? 'active' : ''}`} to={buildRoute(URLS.BAR_DETAIL, URLS.LOCATION, barId)}>Location</Link>
          </ul>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionGetBarDetails,
    ActionModalShowLogin,
    ActionAddToFavoriteBar,
    ActionGetBarInfoByState,
  }, dispatch);
}

function mapStateToProps({rServerData, rUser}) {
  return {
    userId: rUser.id || 0,
    barDetails: rServerData.barDetails || {},
    locationBarList: rServerData.locationBarList || {},
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BarDetailHead);
