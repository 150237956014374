/*
* Author: Dima Bozhenko
* Date Created: 07.02.2019
* Date Modified: 14.03.2019
* Description: Bar Detail Beer Club page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ROUTES from '../../configs/routes';
import SimplyHeader from '../../components/Header/SimplyHeader';
import Loader from '../../components/Loader/Loader';
import BarDetailHead from './BarDetailHead';
import NotFoundBlock from '../../components/UI/NotFoundBlock/NotFoundBlock';
import {
  ActionGetBarDetails,
  ActionGetUserBeerChipList,
  ActionGetBeerChipUserAmount,
  ActionAddToFavoriteBar,
} from '../../store/actions/actions-request';
import {ActionModalCollectBeerchip} from '../../store/actions/actions-modal';
import './BarDetail.less';

class BarDetailBeerClub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barId: this.props.match.params.id,
    };
  }

  componentDidMount() {
    const {barId} = this.state;
    const {beerChipList, beerChipAmount} = this.props;

    if (!beerChipList[barId]) {
      this.props.ActionGetUserBeerChipList(this.props.match.params.id);
    }

    if (!beerChipAmount[barId] && beerChipAmount[barId] !== 0) {
      this.props.ActionGetBeerChipUserAmount(barId);
    }
  }

  collectBeerchip = () => {
    this.props.ActionModalCollectBeerchip({locationId: this.state.barId});
  };

  getStatus = (status) => {
    switch (parseFloat(status)) {
    case 0:
      return 'Pending';
    case 1:
      return 'Available';
    case 2:
      return 'Redeemed';
    case 3:
      return 'Rejected';
    default:
      return 'No Data';
    }
  };

  render() {
    const {
      isLoading, beerChipAmount, beerChipList, barDetailIsLoading,
    } = this.props;
    const {barId} = this.state;
    const beerChipAmountValue = beerChipAmount[barId] || 0;
    const beerChip = beerChipList[barId] || [];

    return (
      <div className="app-container">
        <SimplyHeader title="Bar Detail" back={ROUTES.DASHBOARD} />
        <div className="app-content bar-detail" style={{paddingBottom: '60px'}}>
          <BarDetailHead barId={barId} currentPage="beerClub" />
          <div className="-tabs_content">
            <div className="-tabs_beermug">
              <div className="-balance">
                <div className="-balance_title">Your Current Balance</div>
                <div className="-balance_section">
                  <div className="-balance_points">
                    {beerChipAmountValue} Beerchips
                  </div>
                </div>
              </div>
              <div className="section">
                <div className="section_title">Beerchip collected</div>
                {
                  beerChip.map((item, index) => {
                    return (
                      <div className="-beerchip" key={index}>
                        <div className="-section">
                          <div className="-title">{item.name}</div>
                          <div className="-info">{item.barName}</div>
                          <div className="-data">{item.created_at}</div>
                        </div>
                        <div className="-section">
                          <div className="-points">{item.amount} Beerchips</div>
                          <div className="-status">{this.getStatus(item.status)}</div>
                        </div>
                      </div>
                    );
                  })
                }
                {beerChip.length < 1 &&
                  <NotFoundBlock
                    title="Currently, no beerchips collected"
                  />
                }
              </div>
              <button
                className="bottom-btn"
                onClick={this.collectBeerchip}
              >
                Collect beerchip
              </button>
            </div>
          </div>
        </div>
        <Loader isLoading={isLoading || barDetailIsLoading} />
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionGetBarDetails,
    ActionGetUserBeerChipList,
    ActionGetBeerChipUserAmount,
    ActionAddToFavoriteBar,
    ActionModalCollectBeerchip,
  }, dispatch);
}

function mapStateToProps({rServerData, rPages, rUser}) {
  return {
    rUser,
    barDetails: rServerData.barDetails || {},
    beerChipList: rServerData.beerChipList || {},
    beerChipAmount: rServerData.beerChipAmount || {},
    isLoading: rPages.BarDetailPage.isLoading || false,
    barDetailIsLoading: rPages.BarDetailData.isLoading || false,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BarDetailBeerClub);
