/*
* Author: Gennady Yatsenko
* Date Created: 03.01.2019
* Date Modified: 10.04.2019
* Description: Web utils
* Modification Log:
* License:
*/

import $q from 'q';

export default class UtilsWeb {
  static getFileFromWeb(url) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.open('HEAD', url, true);
      req.setRequestHeader('Access-Control-Allow-Origin', '*');
      req.onreadystatechange = () => {
        if (req.readyState === 4) {
          const size = parseInt(req.getResponseHeader('Content-Length'));
          const xhr = new XMLHttpRequest();

          xhr.open('GET', url, true);
          xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
          xhr.responseType = 'arraybuffer';
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                const type = xhr.getResponseHeader('content-type');
                const blob = new Blob([xhr.response], {type});
                const reader = new FileReader();
                reader.onload = e => resolve({
                  dataUrl: e.target.result,
                  size,
                  type,
                });
                reader.readAsDataURL(blob);
              } else {
                reject(xhr.statusText);
              }
            }
          };
          xhr.send();
        } else if (req.readyState === 0) {
          reject(req.statusText);
        }
      };
      req.send();
    });
  }

  static getLocation() {
    const deferred = $q.defer();
    const data = {
      latitude: 0,
      longitude: 0,
    };
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function success(position) {
      data.latitude = position.coords.latitude;
      data.longitude = position.coords.longitude;
      deferred.resolve(data);
    }

    function error() {
      deferred.resolve(data);
    }

    navigator.geolocation.getCurrentPosition(success, error, options);

    return deferred.promise;
  }
}
