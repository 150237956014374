/*
* Author: Dima Bozhenko
* Date Created: 20.02.2019
* Date Modified: 15.03.2019
* Description: Modal component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ModalBaseLayout from '../ModalBaseLayout';
import {ActionModalHide} from '../../../store/actions/actions-modal';

class ModalAddedBeerChip extends Component {
  constructor(props) {
    super(props);

    this.settings = ModalBaseLayout.getInitialSettings({
      className: 'modal-center',
      closeButton: true,
      keyboard: true,
    });
  }

  doneClickHandle = () => {
    this.props.ActionModalHide();
  };

  render() {
    return (
      <ModalBaseLayout {...this.settings}>
        <div className="modal_box modal_notificate">
          <div className="icon icon--success" />
          <div className="text">Congrats! Your BeerChip will be added to your account within 24 hours</div>
          <div className="btn_wrap">
            <button
              className="btn"
              onClick={this.doneClickHandle}
            >
              Done
            </button>
          </div>
        </div>
      </ModalBaseLayout>
    );
  }
}

ModalAddedBeerChip.propTypes = {};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionModalHide,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddedBeerChip);
