/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Flow reducer
* Modification Log:
* License:
*/

import _ from 'lodash';
import initialFlowModel from '../models/flow-model';
import ConstantsStorage from '../../constants/index';

const ACTIONS = ConstantsStorage().FLOWS_ACTIONS;

export default (state = initialFlowModel(), action) => {
  const newState = _.cloneDeep(state);
  switch (action.type) {
  case ACTIONS.UPDATE:
    return {
      ...newState,
      ...(_.cloneDeep(action.data)),
    };
  default:
    return newState;
  }
};
