/*
* Author: Dima Bozhenko
* Date Created: 24.01.2019
* Date Modified: 14.03.2019
* Description: Profile change password page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ROUTES from '../../configs/routes';
import SimplyHeader from '../../components/Header/SimplyHeader';
import Loader from '../../components/Loader/Loader';
import {ActionChangePassword} from '../../store/actions/actions-user';
import {ActionRouteNavigate} from '../../store/actions/actions-route';
import './Profile.less';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: '',
      email: '',
      oldpassword: '',
      newpassword: '',
      confirmPassword: '',
    };
  }

  componentDidMount() {
    const {full_name, email} = this.props.profileData;

    this.setState({
      full_name,
      email,
    });
  }

  changePassword = () => {
    const {full_name, email, oldpassword, newpassword, confirmPassword} = this.state;
    const data = {
      full_name,
      email,
      oldpassword,
      newpassword,
    };

    if ((newpassword === confirmPassword) && oldpassword && newpassword) {
      this.props.ActionChangePassword(data);
    }
  };

  inputChange = (value, type) => {
    this.setState({
      [type]: value,
    });
  };

  render() {
    const {isLoading} = this.props;
    const {full_name, oldpassword, newpassword, confirmPassword, editable} = this.state;

    return (
      <div className="app-container">
        <div className="app-content">
          <SimplyHeader title="Change Password" back={ROUTES.PROFILE} />
          <div className="profile change-password">
            <div className="-info">
              <div className="-field">
                <div className="-field_title">Full Name (Optional)</div>
                <input
                  type="text"
                  value={full_name}
                  onChange={e => this.inputChange(e.target.value, 'full_name')}
                />
              </div>
              <div className="-field">
                <div className="-field_title">Current Password*</div>
                <input
                  type="password"
                  value={oldpassword}
                  onChange={e => this.inputChange(e.target.value, 'oldpassword')}
                />
              </div>
              <div className="-field">
                <div className="-field_title">Password* (Minimum 6 characters)</div>
                <input
                  type="password"
                  value={newpassword}
                  onChange={e => this.inputChange(e.target.value, 'newpassword')}
                />
              </div>
              <div className="-field">
                <div className="-field_title">Confirm New Password*</div>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={e => this.inputChange(e.target.value, 'confirmPassword')}
                />
              </div>
              {!editable &&
              <div className="-change-psw_wrap">
                <button
                  className="-change-psw"
                  onClick={this.changePassword}
                  disabled={!(oldpassword && (newpassword.length >= 6) && (confirmPassword.length >= 6))}
                > Change password</button>
              </div>
              }
            </div>
          </div>
        </div>
        <Loader isLoading={isLoading} />
      </div>
    );
  }
}

function mapStateToProps({rUser, rPages}) {
  return {
    profileData: rUser,
    isLoading: rPages.ProfilePage.isLoading || false,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionChangePassword,
    ActionRouteNavigate,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
