/*
* Author: Dima Bozhenko
* Date Created: 24.01.2019
* Date Modified: 11.04.2019
* Description: Request actions
* Modification Log:
* License:
*/

import ConstantsStorage from '../../constants/index';
import CommonAPI from '../../api/common';
import FavoriteAPI from '../../api/favorite';
import {NotificationError} from '../../services/notifications';
import {ActionPagesUpdateState} from './actions-pages';
import {ActionModalBeerChipAdded} from './actions-modal';
import {ACTIONS} from '../../constants/data';

const Constants = ConstantsStorage();

export function ActionStoreServerData(key, data, remember) {
  return {
    type: Constants.DATA_ACTIONS.UPDATE,
    key,
    data,
    remember,
  };
}

export function ActionClearServerData() {
  return {
    type: Constants.DATA_ACTIONS.CLEAR,
  };
}

export function ActionGetFavoriteBeers() {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.rUser;

    dispatch(ActionPagesUpdateState('FavoritesPage', {isLoading: true}));

    return FavoriteAPI.favoriteBeers(user)
      .then((res) => {
        dispatch(ActionStoreServerData('beers', res.Data, false));

        if (!res.Success) {
          NotificationError(res.message || 'Oops , something went wrong');
        }
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('FavoritesPage', {isLoading: false}));
      });
  };
}

export function ActionGetFavoriteBars() {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.rUser;

    dispatch(ActionPagesUpdateState('FavoritesPage', {isLoading: true}));

    return FavoriteAPI.favoriteBars(user)
      .then((res) => {
        dispatch(ActionStoreServerData('favoriteBars', res.Data, false));
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('FavoritesPage', {isLoading: false}));
      });
  };
}

export function ActionGetFavoriteStyles() {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.rUser;

    dispatch(ActionPagesUpdateState('FavoritesPage', {isLoading: true}));

    return FavoriteAPI.favoriteStyles(user)
      .then((res) => {
        dispatch(ActionStoreServerData('styles', res.Styles, false));
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('FavoritesPage', {isLoading: false}));
      });
  };
}

export function ActionChangeFavoriteStyle(data) {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.rUser;
    const requestData = {
      ...data,
      conceptId: 7,
      bm_user_id: user.id,
    };

    return FavoriteAPI.changeFavoriteStyles(requestData, user)
      .then((res) => {
        if (res.Message) {
          dispatch(ActionGetFavoriteStyles());
        }
      })
      .finally(() => {
      });
  };
}

export function ActionSearchBeer(data) {
  return (dispatch) => CommonAPI.searchBeer(data)
    .then((res) => {
      dispatch(ActionStoreServerData('searchBeerList', res.product, false));
    })
    .finally(() => {
    });
}

export function ActionSearchBeerCleanList() {
  return (dispatch) => {
    dispatch(ActionStoreServerData('searchBeerList', [], false));
  };
}

export function ActionGetBeerDetails(beerId) {
  return (dispatch, getState) => {
    dispatch(ActionPagesUpdateState('BeerDetailPage', {isLoading: true}));
    const state = getState();
    const profileData = state.rUser;
    const data = {
      userId: profileData.id,
      conceptId: 7,
      beta: 0,
      beerId,
    };

    return CommonAPI.getBeerDetails(data)
      .then((res) => {
        const beerDetails = state.rServerData.beerDetails || {};
        beerDetails[beerId] = res.Data.beerDetail[0] || [];

        dispatch(ActionStoreServerData('beerDetails', beerDetails, false));

        if (!res.Success) {
          NotificationError(res.message || 'Oops , something went wrong');
        }
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(ActionPagesUpdateState('BeerDetailPage', {isLoading: false}));
        }, 1000);
      });
  };
}

export function ActionRateBeer(data) {
  return (dispatch) => CommonAPI.rateBeer(data)
    .then((res) => {
      if (!res.Success) {
        NotificationError(res.Message || 'Oops , something went wrong');
      }

      if (res.Success) {
        dispatch(ActionGetUserBeerRating(data.beer_id));
      }
    })
    .catch(() => {
      NotificationError('Oops , something went wrong');
    })
    .finally(() => {

    });
}

export function ActionLoadBarList(searchMarket) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.rUser.id;
    const conceptId = 7;
    const market = searchMarket || 'Atlanta,GA';
    const requestData = {
      userId,
      conceptId,
      market,
    };

    dispatch(ActionPagesUpdateState('DashboardPage', {isLoading: true}));
    dispatch(ActionStoreServerData('barsCities', null));
    dispatch(ActionStoreServerData('beerList', null));

    CommonAPI.getBarList(requestData)
      .then((res) => {
        const barsList = typeof res.Data.barsDetail === 'string' ? [] : res.Data.barsDetail;

        dispatch(ActionStoreServerData('bars', barsList));
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('DashboardPage', {isLoading: false}));
      });
  };
}

export function ActionLoadLocations(searchField) {
  return (dispatch) => {
    const requestData = {
      conceptId: 7,
      searchField,
    };

    dispatch(ActionPagesUpdateState('SearchLocationInput', {isLoading: true}));
    dispatch(ActionStoreServerData('barsCities', null));

    CommonAPI.getLocations(requestData)
      .then((res) => {
        dispatch(ActionStoreServerData('barsCities', res.Cities));
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('SearchLocationInput', {isLoading: false}));
      });
  };
}

export function ActionLoadBeerList(beerSearch, searchMarket) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.rUser.id;
    const requestData = {
      conceptId: 7,
      userId,
      q: beerSearch,
      searchField: beerSearch,
      searchType: 1,
      beta: 0,
    };

    if (searchMarket) {
      requestData.searchMarket = searchMarket;
    }

    dispatch(ActionPagesUpdateState('SearchBeerInput', {isLoading: true}));
    dispatch(ActionStoreServerData('beerList', null));
    dispatch(ActionStoreServerData('searchBarList', null));

    CommonAPI.getBeerList(requestData)
      .then((res) => {
        dispatch(ActionStoreServerData('beerList', res.product));
        dispatch(ActionStoreServerData('searchBarList', res.location));
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('SearchBeerInput', {isLoading: false}));
      });
  };
}

export function ActionGetBarBeerList(id) {
  return (dispatch, getState) => {
    const data = {
      conceptId: 7,
      locationId: id,
    };

    dispatch(ActionPagesUpdateState('BarMenu', {isLoading: true}));

    CommonAPI.getBarBeerList(data)
      .then((res) => {
        const barBeerList = getState().rServerData.barBeerList || {};
        barBeerList[id] = res;

        dispatch(ActionStoreServerData('barBeerList', barBeerList));
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('BarMenu', {isLoading: false}));
      });
  };
}

export function ActionGetUserBeerRating(id) {
  return (dispatch, getState) => {
    const data = {
      conceptId: 7,
      userId: getState().rUser.id,
      beerId: id,
    };

    dispatch(ActionPagesUpdateState('UserBeerRating', {isLoading: true}));

    CommonAPI.getUserBeerRating(data)
      .then((res) => {
        const beerRatings = getState().rServerData.beerRatings || {};
        beerRatings[id] = res.BeerRatings[0];

        dispatch(ActionStoreServerData('beerRatings', beerRatings));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(ActionPagesUpdateState('UserBeerRating', {isLoading: false}));
        }, 1000);
      });
  };
}

export function ActionAddToFavoriteBeer(id, isFavorite) {
  return (dispatch, getState) => {
    const data = {
      user_id: getState().rUser.id,
      reference_type: 'beer',
      reference_id: id,
      is_favorite: isFavorite,
      conceptId: 7,
    };

    CommonAPI.addToFavorite(data)
      .then(() => {
        dispatch(ActionGetBeerDetails(id));
      });
  };
}

export function ActionAddToFavoriteBar(id, isFavorite) {
  return (dispatch, getState) => {
    const data = {
      user_id: getState().rUser.id,
      reference_type: 'bar',
      reference_id: id,
      is_favorite: isFavorite,
      conceptId: 7,
    };
    CommonAPI.addToFavorite(data)
      .then(() => {
        dispatch(ActionGetBarDetails(id));
      });
  };
}

export function ActionGetAllBarList() {
  return (dispatch, getState) => {
    dispatch(ActionPagesUpdateState('BarDetailPage', {isLoading: true}));
    const state = getState();
    const userId = state.rUser.id;
    const conceptId = 7;
    const market = '';
    const requestData = {
      userId,
      conceptId,
      market,
    };

    CommonAPI.getAllBarList(requestData)
      .then((res) => {
        dispatch(ActionStoreServerData('barList', res.Data.barsDetail));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(ActionPagesUpdateState('BarDetailPage', {isLoading: false}));
        }, 1000);
      });
  };
}

export function ActionGetLocationBars() {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.rUser;
    const data = {
      userId: user.id,
      longitude: user.longitude,
      latitude: user.latitude,
      conceptId: 7,
    };

    dispatch(ActionPagesUpdateState('DashboardPage', {isLoading: true}));
    dispatch(ActionStoreServerData('barsCities', null));
    dispatch(ActionStoreServerData('beerList', null));

    return CommonAPI.getLocationBars(data)
      .then((res) => {
        const barsList = typeof res.Data.barsDetail === 'string' ? [] : res.Data.barsDetail;

        dispatch(ActionStoreServerData('bars', barsList));
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('DashboardPage', {isLoading: false}));
        dispatch(ActionPagesUpdateState('LoginPage', {isLoading: false}));
      });
  };
}

export function ActionGetBarDetails(id, getOtherInfo) {
  return (dispatch, getState) => {
    const state = getState();
    const data = {
      locationId: id,
      userId: state.rUser.id,
      conceptId: 7,
    };

    dispatch(ActionPagesUpdateState('BarDetailData', {isLoading: true}));
    return CommonAPI.getBarDetails(data)
      .then((res) => {
        const barDetailsList = state.rServerData.barDetails || {};
        const barDetails = res.Data.barsDetail[0];
        barDetailsList[id] = barDetails;

        dispatch(ActionStoreServerData('barDetails', barDetailsList));
        if (getOtherInfo) {
          dispatch(ActionGetBarInfoByState(barDetails.addrCity, barDetails.addrState));
        }
      })
      .finally(() => {
        dispatch(ActionPagesUpdateState('BarDetailData', {isLoading: false}));
      });
  };
}

export function ActionGetUserBeerChipList(locationId) {
  return (dispatch, getState) => {
    const data = {
      conceptId: 7,
      userId: getState().rUser.id,
      locationId,
    };

    dispatch(ActionPagesUpdateState('BarDetailPage', {isLoading: true}));

    return CommonAPI.getUserBeerChipList(data)
      .then((res) => {
        const beerChipList = getState().rServerData.beerChipList || {};
        const beerChip = res.beerChip || [];
        beerChipList[locationId] = beerChip;

        dispatch(ActionStoreServerData('beerChipList', beerChipList));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(ActionPagesUpdateState('BarDetailPage', {isLoading: false}));
        }, 1000);
      });
  };
}

export function ActionGetBeerChipUserAmount(locationId) {
  return (dispatch, getState) => {
    const data = {
      conceptId: 7,
      userId: getState().rUser.id,
      locationId,
    };

    dispatch(ActionPagesUpdateState('BarDetailPage', {isLoading: true}));

    return CommonAPI.getBeerChipUserAmount(data)
      .then((res) => {
        const beerChipAmountList = getState().rServerData.beerChipAmountList || {};
        const beerChipAmount = res.amount || 0;
        beerChipAmountList[locationId] = beerChipAmount;

        dispatch(ActionStoreServerData('beerChipAmount', beerChipAmountList));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(ActionPagesUpdateState('BarDetailPage', {isLoading: false}));
        }, 1000);
      });
  };
}

export function ActionGetBarInfoByState(city, state) {
  return (dispatch, getState) => {
    dispatch(ActionPagesUpdateState('BarDetailPage', {isLoading: true}));

    const data = {
      city,
      state,
      userId: getState().rUser.id,
    };

    return CommonAPI.getBarInfoByState(data)
      .then((res) => {
        const locationBarList = getState().rServerData.locationBarList || {};
        const barList = res.Data.barsDetail || [];
        const location = `${city},${state}`;
        locationBarList[location] = barList;
        dispatch(ActionStoreServerData('locationBarList', locationBarList));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(ActionPagesUpdateState('BarDetailPage', {isLoading: false}));
        }, 1000);
      });
  };
}

export function ActionCollectBeerchip(data) {
  return (dispatch, getState) => {
    const reqData = data;
    reqData.userId = getState().rUser.id;
    reqData.beta = 0;
    reqData.conceptId = 7;

    dispatch(ActionPagesUpdateState('BarDetailPage', {isLoading: true}));

    return CommonAPI.collectBeerchip(reqData)
      .then(() => {
        dispatch(ActionGetUserBeerChipList(data.locationId));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(ActionModalBeerChipAdded());
          dispatch(ActionPagesUpdateState('BarDetailPage', {isLoading: false}));
        }, 1000);
      });
  };
}

export function ActionSetLocation(location) {
  return (dispatch, getState) => {
    dispatch(ActionStoreServerData('currentLocation', location));
  };
}

export function ActionClearLocation() {
  return (dispatch) => {
    dispatch(ActionStoreServerData('barsCities', null));
  };
}
