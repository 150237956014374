/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Main reducer
* Modification Log:
* License:
*/

import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import rModals from './reducer-modals';
import rViewport from './reducer-viewport';
import rUser from './reducer-user';
import rSession from './reducer-session';
import rPages from './reducer-flow';
import rServerData from './reducer-server-data';

export const RootReducer = (history) => (
  combineReducers({
    router: connectRouter(history),
    rModals,
    rViewport,
    rUser,
    rSession,
    rPages,
    rServerData,
  })
);
