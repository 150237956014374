/*
* Author: Dima Bozhenko
* Date Created: 24.01.2019
* Date Modified: 14.03.2019
* Description: Beer Detail page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SimplyHeader from '../../components/Header/SimplyHeader';
import Like from '../../components/UI/Like/Like';
import Rate from '../../components/UI/Rate/Rate';
import Loader from '../../components/Loader/Loader';
import {ActionModalShowBeerRate, ActionModalShowLogin} from '../../store/actions/actions-modal';
import {
  ActionGetBeerDetails,
  ActionGetUserBeerRating,
  ActionAddToFavoriteBeer,
} from '../../store/actions/actions-request';
import './BeerDetail.less';
import BeerDetailCharacteristicBlock
  from '../../components/BeerDetailCharacteristicBlock/BeerDetailCharacteristicBlock';

class BeerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beerId: this.props.match.params.id,
    };
  }

  componentDidMount() {
    this.props.ActionGetBeerDetails(this.state.beerId);
    this.props.ActionGetUserBeerRating(this.state.beerId);
  }

  beerRate = (value, beerName) => {
    if (this.props.rUser.guest) {
      this.props.ActionModalShowLogin();
      return null;
    }

    this.props.ActionModalShowBeerRate({
      beerId: this.state.beerId,
      value,
      beerName,
    });
  };

  likeClickHandle = (value) => {
    if (this.props.rUser.guest) {
      this.props.ActionModalShowLogin();
      return null;
    }

    const isFavorite = value === 1 ? 0 : 1;

    this.props.ActionAddToFavoriteBeer(this.state.beerId, isFavorite);
  };

  render() {
    const {
      beerDetails, beerRatings, isLoading, UserBeerRatingIsLoading,
    } = this.props;
    const beer = beerDetails[this.state.beerId] || {};
    const beerRating = beerRatings[this.state.beerId] || {};
    const beerRatingValue = beerRating.VALUE || 0;

    return (
      <div className="app-container">
        <SimplyHeader title="Beer Detail" />
        <div className="app-content beer-detail">
          <div className="beer-detail_head">
            <div className="-title">{beer.name}</div>
            <Rate value={beerRatingValue} />
            <div className="-like">
              <Like
                value={beer.isFavorite}
                onClick={() => this.likeClickHandle(beer.isFavorite)}
              />
            </div>
          </div>
          <div className="beer-detail_content">
            <div className="-characteristic">
              <BeerDetailCharacteristicBlock
                name="STYLE"
                value={beer.style}
              />
              <BeerDetailCharacteristicBlock
                name="ORIGIN"
                value={beer.origin}
              />
              <BeerDetailCharacteristicBlock
                name="ABV"
                value={beer.abv}
              />
              <BeerDetailCharacteristicBlock
                name="IBU"
                value={beer.ibu}
              />
              <BeerDetailCharacteristicBlock
                name="CALORIES"
                value={beer.calories}
              />
              <BeerDetailCharacteristicBlock
                name="Beer Description"
                value={beer.description}
                full
              />
              <BeerDetailCharacteristicBlock
                name="Goes Best with"
                value={beer.foodPairing}
                full
              />
            </div>
            <div className="-btn_container">
              <button className="-btn" onClick={() => this.beerRate(beerRatingValue, beer.name)}>
                Rate Beer
              </button>
            </div>
          </div>
        </div>
        <Loader isLoading={isLoading || UserBeerRatingIsLoading} />
      </div>
    );
  }
}

function mapStateToProps({rServerData, rPages, rUser}) {
  return {
    rUser,
    beerDetails: rServerData.beerDetails || {},
    beerRatings: rServerData.beerRatings || {},
    isLoading: rPages.BeerDetailPage.isLoading || false,
    UserBeerRatingIsLoading: rPages.UserBeerRating.isLoading || false,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionModalShowBeerRate,
    ActionGetBeerDetails,
    ActionGetUserBeerRating,
    ActionAddToFavoriteBeer,
    ActionModalShowLogin,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BeerDetail);
