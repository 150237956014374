/*
* Author: Dima Bozhenko
* Date Created: 24.01.2019
* Date Modified: 15.03.2019
* Description: Map component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {GoogleApiWrapper} from 'google-maps-react';
import ConfigStorage from '../../configs';
import image from '../../images/required/map-marker.png';

let map;

class Map extends Component {
  componentDidMount() {
    this.initMap();
  }

  initMap = () => {
    const mapContainer = document.getElementById('map');
    const mapPosition = {
      lat: this.props.lat,
      lng: this.props.lng,
    };

    map = new google.maps.Map(mapContainer, {
      center: mapPosition,
      zoom: 16,
      mapTypeControl: false,
      scaleControl: false,
      zoomControl: false,
    });

    let icon = {
      url: image, // url
      size: new google.maps.Size(30, 30),
      scaledSize: new google.maps.Size(30, 30),
    };

    let marker = new google.maps.Marker({
      position: mapPosition,
      map,
      icon,
    });
  };

  render() {
    return (
      <div id="map" className="-map" />
    );
  }
}

Map.propTypes = {};

Map.defaultProps = {};

export default GoogleApiWrapper({
  apiKey: (ConfigStorage().API_SETTINGS.GOOGLE_MAPS_API_KEY),
})(Map);
