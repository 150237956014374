/*
* Author: Dima Bozhenko
* Date Created: 25.01.2019
* Date Modified: 14.03.2019
* Description: Welcome page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ROUTES from 'configs/routes';
import {ActionRouteNavigate} from 'store/actions/actions-route';
import './Login.less';

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  loginClickHandle = (e) => {
    e.preventDefault();
    this.props.ActionRouteNavigate(ROUTES.LOGIN);
  };

  signupClickHandle = (e) => {
    e.preventDefault();
    this.props.ActionRouteNavigate(ROUTES.SIGNUP);
  };

  guestClickHandle = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <div className="app-container">
        <div className="login">
          <div className="-head">
            <div className="-logo" />
            <div className="-powered">
              <span>Powered By</span>
              <div className="-beerboard" />
            </div>
          </div>
          <div className="-content">
            <div className="-title" style={{marginBottom: '60px'}}>Welcome to Mellow Mushroom</div>
            <div className="-btns">
              <div className="-btns_field">
                <div className="-name">Already Have an Account?</div>
                <button
                  className="-btn -btn--login"
                  onClick={this.loginClickHandle}
                >
                  Login
                </button>
              </div>
              <div className="-btns_field">
                <div className="-name">A New User?</div>
                <button
                  className="-btn -btn--signup"
                  onClick={this.signupClickHandle}
                >
                  SignUp
                </button>
              </div>
              <div className="-btns_field">
                <div className="-name">Want to try the Application?</div>
                <button
                  className="-btn -btn--guest"
                  onClick={this.loginClickHandle}
                >
                  Be a Guest
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Welcome.propTypes = {};

Welcome.defaultProps = {};

function mapStateToProps({rLoginPage}) {
  return {rLoginPage};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionRouteNavigate,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
