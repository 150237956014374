/*
* Author: Gennadiy Yatsenko
* Date Created: 04.02.2019
* Date Modified: 14.03.2019
* Description: Favorites Bars page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import ROUTES, {buildRoute} from '../../configs/routes';
import URL_MAP from '../../constants/urls';
import Header from '../../components/Header/Header';
import NavBar from '../../components/NavBar/NavBar';
import Loader from '../../components/Loader/Loader';
import {
  ActionGetFavoriteBars,
} from '../../store/actions/actions-request';
import './Favorites.less';

class FavoritesBars extends Component {
  componentDidMount() {
    this.props.ActionGetFavoriteBars();
  }

  render() {
    const {favoriteBars, isLoading} = this.props;
    const tabsData = [
      {
        route: ROUTES.FAVORITES_BARS,
        name: 'BARS',
      },
      {
        route: ROUTES.FAVORITES_BEERS,
        name: 'BEERS',
      },
      {
        route: ROUTES.FAVORITES_STYLES,
        name: 'STYLES',
      },
    ];

    return (
      <div className="app-container">
        <div className="app-content favorites">
          <Header logo={false} title="Favorites" />
          <div className="favorites_head">
            <NavBar activeIndex={0} data={tabsData} />
          </div>
          <div className="favorites_content">
            <div className="bars-near">
              {
                favoriteBars.map((bar, index) => {
                  return (
                    <Link to={buildRoute(URL_MAP.BAR_DETAIL, URL_MAP.BEER_LIST, `${bar.locationId}`)} className="-block" key={index}>
                      <div className="-title">{bar.boardname}</div>
                      <div className="-descr">{bar.addrStreet} , {bar.addrCity} <br /> {bar.addrState} {bar.addrZip}</div>
                      <div className="-section">
                        <div className="-info">Open Hours {bar.hours}</div>
                        <div className="-info">{bar.distance.toFixed(2)} miles</div>
                      </div>
                    </Link>
                  );
                })
              }
              {!favoriteBars.length && <div className="-title text-center" style={{textAlign: 'center', marginTop: '150px'}}> No Favorite Bar </div>}
            </div>
          </div>
        </div>
        <Loader isLoading={isLoading} />
      </div>
    );
  }
}

function mapStateToProps({rServerData, rPages}) {
  return {
    favoriteBars: rServerData.favoriteBars || [],
    isLoading: rPages.FavoritesPage.isLoading || false,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionGetFavoriteBars,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesBars);
