/*
* Author: Gennady Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Validation utils
* Modification Log:
* License:
*/

import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {RootReducer} from './reducers';
import ConfigStorage from '../configs';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory({
  basename: ConfigStorage().ROUTES.ROOT,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['rServerData', 'rSession', 'rUser'],
};

const persistedReducer = persistReducer(persistConfig, RootReducer(history));

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(
    thunk,
    routerMiddleware(history),
  )),
);

export const persistor = persistStore(store);
