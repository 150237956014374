/*
* Author: Dima Bozhenko
* Date Created: 05.02.2019
* Date Modified: 15.03.2019
* Description: Modal component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ModalBaseLayout from '../ModalBaseLayout';
import {ActionModalHide} from '../../../store/actions/actions-modal';
import {ActionRouteNavigate} from '../../../store/actions/actions-route';

class ModalRegisterNotSuccess extends Component {
  constructor(props) {
    super(props);

    this.settings = ModalBaseLayout.getInitialSettings({
      className: 'modal-center',
      closeButton: true,
      keyboard: true,
    });
  }

  exitClickHandle = () => {
    this.props.ActionModalHide();
  };

  render() {
    const message = this.props.message || 'Oops , something went wrong';

    return (
      <ModalBaseLayout {...this.settings}>
        <div className="modal_box modal_notificate">
          <div className="icon icon--notsuccess" />
          <div className="text">{message}</div>
          <button
            className="modal_close"
            onClick={this.exitClickHandle}
          />
        </div>
      </ModalBaseLayout>
    );
  }
}

ModalRegisterNotSuccess.propTypes = {};

function mapStateToProps({}) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionRouteNavigate,
    ActionModalHide,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalRegisterNotSuccess);
