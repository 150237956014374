/*
* Author: Dima Bozhenko
* Date Created: 24.01.2019
* Date Modified: 14.03.2019
* Description: Notification service
* Modification Log:
* License:
*/

import {toast} from 'react-toastify';

export function NotificationError(msg, title = '', time = 5000, onClick = null) {
  toast.error(msg, {
    position: 'top-right',
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: onClick,
    pauseOnHover: true,
    draggable: true,
  });
}

export function NotificationSuccess(msg, title = '', time = 5000, onClick = null) {
  toast.success(msg, {
    position: 'top-right',
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: onClick,
    pauseOnHover: true,
    draggable: true,
  });
}
