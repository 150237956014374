/*
* Author: Dima Bozhenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Constants
* Modification Log:
* License:
*/

const ACTIONS = {
  DIALOG_SHOW: 'DIALOG_SHOW',
  DIALOG_HIDE: 'DIALOG_HIDE',
  DIALOG_SET_PARAMS: 'DIALOG_SET_PARAMS',
};

export default ACTIONS;

const DIALOG_TYPES = {
  MODAL_TYPE_EXAMPLE: 'MODAL_TYPE_EXAMPLE',
  BEER_RATE: 'BEER_RATE',
  AGE_CONFIRM_NOT_SUCCESS: 'AGE_CONFIRM_NOT_SUCCESS',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_NOTSUCCESS: 'REGISTER_NOTSUCCESS',
  NOT_FOUND_USER: 'NOT_FOUND_USER',
  NOT_FOUND_ACCOUNT: 'NOT_FOUND_ACCOUNT',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_NOTSUCCESS: 'CHANGE_PASSWORD_NOTSUCCESS',
  PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
  PROFILE_UPDATE_NOTSUCCESS: 'PROFILE_UPDATE_NOTSUCCESS',
  LOGIN: 'LOGIN',
  COLLECT_BEERCHIP: 'COLLECT_BEERCHIP',
  MAP_LINK: 'MAP_LINK',
  OPEN_HOURS: 'OPEN_HOURS',
  BEERCHIP_ADDED: 'BEERCHIP_ADDED',
};

export {DIALOG_TYPES};
