/*
* Author: Dima Bozhenko
* Date Created: 04.02.2019
* Date Modified: 15.03.2019
* Description: Modal component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ModalBaseLayout from '../ModalBaseLayout';
import {ActionModalHide} from '../../../store/actions/actions-modal';

class ModalNotFoundAccount extends Component {
  constructor(props) {
    super(props);

    this.settings = ModalBaseLayout.getInitialSettings({
      className: 'modal-center',
      closeButton: true,
      keyboard: true,
    });
  }

  exitClickHandle = () => {
    this.props.ActionModalHide();
  };

  render() {
    return (
      <ModalBaseLayout {...this.settings}>
        <div className="modal_box modal_notificate">
          <div className="icon icon--notsuccess" />
          <div className="text">We couldn`t find an account matching the Username and Password you entered.
            Please check your Username and Password and try again.
          </div>
          <button
            className="modal_close"
            onClick={this.exitClickHandle}
          />
        </div>
      </ModalBaseLayout>
    );
  }
}

ModalNotFoundAccount.propTypes = {};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionModalHide,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalNotFoundAccount);
