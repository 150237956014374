/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Route actions
* Modification Log:
* License:
*/

import {push, goBack, goForward} from 'connected-react-router';

export function ActionRouteNavigate(location) {
  return push(location);
}

export function ActionRouteGoBack() {
  return goBack();
}

export function ActionRouteGoForward() {
  return goForward();
}
