/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Constants
* Modification Log:
* License:
*/

const VIEWPORT_ACTIONS = {
  TOGGLE_MAIN_MENU: 'TOGGLE_MAIN_MENU',
};

export default VIEWPORT_ACTIONS;

const BUTTON_TYPES = {
  CLOSE: 'CLOSE',
  CANCEL: 'CANCEL',
};

export {BUTTON_TYPES};
