/*
* Author: Yatsenko Gennadiy
* Date Created: 03.01.2019
* Date Modified: 15.03.2019
* Description:
* Modification Log:
* License:
*/

import React from 'react';
import PropTypes from 'prop-types';
import {Route, Switch, Redirect} from 'react-router';
import {auth, notAuth} from './utils/utils-auth';
import ROUTES from './configs/routes';
import RootLayout from './components/RootLayout/RootLayout';
import AgeConfirm from './pages/Login/AgeConfirm';
import Login from './pages/Login/Login';
import Welcome from './pages/Login/Welcome';
import SignUp from './pages/Login/SignUp';
import ForgotPassword from './pages/Login/ForgotPassword';
import ResetPassword from './pages/Login/ResetPassword';
import Dashboard from './pages/Dashboard/Dashboard';
import Profile from './pages/Profile/Profile';
import ChangePassword from './pages/Profile/ChangePassword';
import FavoritesBars from './pages/Favorites/FavoritesBars';
import FavoritesBeers from './pages/Favorites/FavoritesBeers';
import FavoritesStyles from './pages/Favorites/FavoritesStyles';
import SupportFaq from './pages/Support/SupportFaq';
import SupportPrivacy from './pages/Support/SupportPrivacy';
import SupportTerms from './pages/Support/SupportTerms';
import BeerDetail from './pages/BeerDetail/BeerDetail';
import BarDetailBeerList from './pages/BarDetail/BarDetailBeerList';
import BarDetailBeerClub from './pages/BarDetail/BarDetailBeerClub';
import BarDetailLocation from './pages/BarDetail/BarDetailLocation';

function Routes(props) {
  return (
    <RootLayout>
      <Switch>
        <Redirect exact from={ROUTES.ROOT} to={ROUTES.DASHBOARD} />
        <Route exact path={ROUTES.AGE_CONFIRM} component={notAuth(AgeConfirm)} />
        <Route exact path={ROUTES.LOGIN} component={notAuth(Login)} />
        <Route exact path={ROUTES.WELCOME} component={notAuth(Welcome)} />
        <Route exact path={ROUTES.SIGNUP} component={notAuth(SignUp)} />
        <Route exact path={ROUTES.FORGOT_PASSWORD} component={notAuth(ForgotPassword)} />
        <Route exact path={ROUTES.RESET_PASSWORD} component={notAuth(ResetPassword)} />
        <Route exact path={ROUTES.DASHBOARD} component={auth(Dashboard)} />
        <Route exact path={ROUTES.PROFILE} component={auth(Profile)} />
        <Route exact path={ROUTES.PROFILE_CHANGE_PASSWORD} component={auth(ChangePassword)} />
        <Route exact path={ROUTES.FAVORITES_BARS} component={auth(FavoritesBars)} />
        <Route exact path={ROUTES.FAVORITES_BEERS} component={auth(FavoritesBeers)} />
        <Route exact path={ROUTES.FAVORITES_STYLES} component={auth(FavoritesStyles)} />
        <Route exact path={ROUTES.SUPPORT_FAQ} component={auth(SupportFaq)} />
        <Route exact path={ROUTES.SUPPORT_PRIVACY} component={auth(SupportPrivacy)} />
        <Route exact path={ROUTES.SUPPORT_TERMS} component={auth(SupportTerms)} />
        <Route exact path={ROUTES.BAR_DETAIL_BEER_LIST} component={auth(BarDetailBeerList)} />
        <Route exact path={ROUTES.BAR_DETAIL_BEER_CLUB} component={auth(BarDetailBeerClub)} />
        <Route exact path={ROUTES.BAR_DETAIL_LOCATION} component={auth(BarDetailLocation)} />
        <Route exact path={ROUTES.BEER_DETAIL} component={auth(BeerDetail)} />
      </Switch>
    </RootLayout>
  );
}

Routes.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Routes;
