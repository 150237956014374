/*
* Author: Dima Bozhenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Modal actions
* Modification Log:
* License:
*/

import ConstantsStorage from '../../constants/index';

const ACTIONS = ConstantsStorage().DIALOG_ACTIONS;
const TYPES = ConstantsStorage().DIALOG_TYPES;

export function ActionModalShow(template, data = {}) {
  return {
    type: ACTIONS.DIALOG_SHOW,
    template,
    data,
  };
}

export function ActionModalHide(callback) {
  if (callback && _.isFunction(callback)) {
    callback();
  }

  return {
    type: ACTIONS.DIALOG_HIDE,
  };
}

export function ActionModalShowBeerRate(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.BEER_RATE, data));
  };
}

export function ActionModalShowAgeConfirmNotSuccess(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.AGE_CONFIRM_NOT_SUCCESS, data));
  };
}

export function ActionModalRegisterSuccess(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.REGISTER_SUCCESS, data));
  };
}

export function ActionModalChangePasswordSuccess(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.CHANGE_PASSWORD_SUCCESS, data));
  };
}

export function ActionModalChangePasswordNotSuccess(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.CHANGE_PASSWORD_NOTSUCCESS, data));
  };
}

export function ActionModalNotFoundUser(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.NOT_FOUND_USER, data));
  };
}

export function ActionModalNotFoundAccount(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.NOT_FOUND_ACCOUNT, data));
  };
}

export function ActionModalProfileUpdateSuccess(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.PROFILE_UPDATE_SUCCESS, data));
  };
}

export function ActionModalProfileUpdateNotSuccess(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.PROFILE_UPDATE_NOTSUCCESS, data));
  };
}

export function ActionModalShowLogin(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.LOGIN, data));
  };
}

export function ActionModalRegisterNotSuccess(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.REGISTER_NOTSUCCESS, data));
  };
}

export function ActionModalCollectBeerchip(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.COLLECT_BEERCHIP, data));
  };
}

export function ActionModalMapLink(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.MAP_LINK, data));
  };
}

export function ActionModalOpenHours(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.OPEN_HOURS, data));
  };
}

export function ActionModalBeerChipAdded(data) {
  return (dispatch) => {
    dispatch(ActionModalShow(TYPES.BEERCHIP_ADDED, data));
  };
}
