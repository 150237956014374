/*
* Author: Dima Bozhenko
* Date Created: 04.02.2019
* Date Modified: 15.03.2019
* Description: Support FAQ page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ROUTES from '../../configs/routes';
import Header from '../../components/Header/Header';
import NavBar from '../../components/NavBar/NavBar';
import './Support.less';

class SupportFaq extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeAccordeon: undefined,
    };
  }

  changeActiveAccordion = (id) => {
    const {activeAccordeon} = this.state;

    this.setState({
      activeAccordeon: id === activeAccordeon ? undefined : id,
    });
  };

  render() {
    const tabsData = [
      {
        route: ROUTES.SUPPORT_FAQ,
        name: 'FAQ\'S',
      },
      {
        route: ROUTES.SUPPORT_PRIVACY,
        name: 'PRIVACY',
      },
      {
        route: ROUTES.SUPPORT_TERMS,
        name: 'TERMS',
      },
    ];
    const faqContent = [
      {
        id: 1,
        title: 'What/Where is the Check ID #?',
        text: 'The Check ID # can be found on top of the\n' +
          'receipt your bartender/waiter gives you\n' +
          'also known as the “Pre-Checkout” check.',
      },
      {
        id: 2,
        title: 'How long do I have to enter in my Check ID #?',
        text: 'You have 48 Hours to enter in your Check ID # before it expires.',
      },
      {
        id: 3,
        title: 'How fast will my BeerChip be accepted?',
        text: 'We receive the sales information from the restaurant the next morning. Your BeerChip will be redeemed by the next day. If your BeerChip is still pending, check back in a few hours.',
      },
    ];

    return (
      <div className="app-container">
        <div className="app-content favorites">
          <Header logo />
          <div className="favorites_head">
            <NavBar data={tabsData} activeIndex={0} />
          </div>
          <div className="favorites_content">
            <div className="-tabs_accordion">
              {
                faqContent.map(content => (
                  <div key={content.id} className={`accordion ${content.id === this.state.activeAccordeon ? 'active' : ''}`}>
                    <div className="accordion_head" onClick={() => this.changeActiveAccordion(content.id)}>
                      <div className="-text">{content.title}</div>
                    </div>
                    <div className="accordion_content">
                      {content.text}
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="favorites_notice">
              <div className="-text">And remember please drink responsibly and don't drink and drive</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportFaq);
