/*
* Author: Dima Bozhenko
* Date Created: 25.01.2019
* Date Modified: 14.03.2019
* Description: Reset Password page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ActionResetPassword, ActionResendOTP, ActionOTPClear} from '../../store/actions/actions-user';
import './Login.less';
import Loader from '../../components/Loader/Loader';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: '',
      password: '',
      confirmPassword: '',
    };
  }

  componentWillUnmount(){
    this.props.ActionOTPClear();
  }

  recoverClickHandle = (e) => {
    e.preventDefault();
    const {otp, password} = this.state;
    const data = {
      email: this.props.rServerData.resetPasswordData.email,
      resettoken: otp,
      password,
      beta: 0,
      conceptId: 7,
    };

    this.props.ActionResetPassword(data);
  };

  inputChangeHandle = (e, type) => {
    this.setState({
      [type]: e.target.value,
    });
  };

  resendOTP = () => {
    const email = this.props.rServerData.resetPasswordData.email;
    this.props.ActionResendOTP(email);
  };

  render() {
    const {otp, password, confirmPassword} = this.state;
    const {isLoading, isHasBeenSentOTP} = this.props;
    console.log(isHasBeenSentOTP);

    return (
      <div className="app-container">
        <div className="login">
          <div className="-head">
            <div className="-logo" />
            <div className="-powered">
              <span>Powered By</span>
              <div className="-beerboard" />
            </div>
          </div>
          <div className="-content">
            <div className="-title" style={{marginBottom: '40px'}}>Reset Password</div>
            {isHasBeenSentOTP &&
            <p className="-text -text--request">
              Success! OTP (One-Time Passcode) has been sent to your registered Email-Id: <br/>
              {this.props.rServerData.resetPasswordData.email}
            </p>
            }
            <div className="-field -field--password">
              <input
                type="text"
                inputMode="numeric"
                pattern="\d*"
                placeholder="(OTP (One-Time Password)"
                name="otp"
                required
                value={otp}
                onChange={e => this.inputChangeHandle(e, 'otp')}
              />
            </div>
            <div className="-field -field--password">
              <input
                type="password"
                placeholder="New Password (Minimum 6 characters)"
                name="password"
                required
                value={password}
                onChange={e => this.inputChangeHandle(e, 'password')}
              />
            </div>
            <div className="-field -field--password">
              <input
                type="password"
                placeholder="Confirm Password"
                name="password"
                required
                value={confirmPassword}
                onChange={e => this.inputChangeHandle(e, 'confirmPassword')}
              />
            </div>
            <div className="-btns_field">
              <button
                className="-btn -btn--login"
                onClick={this.recoverClickHandle}
                disabled={!(otp && ((password.length >= 6) && (confirmPassword.length >= 6)) && (password === confirmPassword))}
              >
                Reset Password
              </button>
            </div>
            <div className="-btns_field">
              <button className="resend-otp-btn" onClick={this.resendOTP}> Resend OTP</button>
            </div>
          </div>
        </div>
        <Loader isLoading={isLoading} />
      </div>
    );
  }
}

ResetPassword.propTypes = {};

ResetPassword.defaultProps = {};

function mapStateToProps({rServerData, rPages}) {
  return {
    rServerData,
    isLoading: rPages.ResetPasswordPage.isLoading || false,
    isHasBeenSentOTP: rPages.isHasBeenSentOTP || false,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionResetPassword,
    ActionResendOTP,
    ActionOTPClear,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
