/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Session model
* Modification Log:
* License:
*/

function initialSessionModel() {
  return {};
}

export {initialSessionModel};
