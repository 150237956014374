/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: User reducer
* Modification Log:
* License:
*/

import _ from 'lodash';
import ConstantsStorage from '../../constants/index';
import {initialUserModel} from '../models/user-model';

let ACTIONS = ConstantsStorage().USER_ACTIONS;

export default (state = initialUserModel(), action) => {
  let newState;
  switch (action.type) {
  case ACTIONS.UPDATE:
    newState = _.cloneDeep(state);
    return {...newState, ...action.data};
  case ACTIONS.CLEAR:
    return {};
  default:
    return state;
  }
};
