/*
* Author: Dima Bozhenko
* Date Created: 07.02.2019
* Date Modified: 06.05.2019
* Description: Bar Detail Beer list page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ROUTES from 'configs/routes';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _ from 'lodash';
import {buildRoute} from '../../configs/routes';
import URLS from '../../constants/urls';
import SimplyHeader from '../../components/Header/SimplyHeader';
import Loader from '../../components/Loader/Loader';
import NotFoundBlock from '../../components/UI/NotFoundBlock/NotFoundBlock';
import SearchInput from '../../components/UI/SearchInput/SearchInput';
import BarDetailHead from './BarDetailHead';
import {ActionGetBarBeerList, ActionGetBarDetails, ActionAddToFavoriteBar} from '../../store/actions/actions-request';
import {ActionModalShowLogin} from '../../store/actions/actions-modal';
import './BarDetail.less';

class BarDetailBeerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBeer: '',
      barId: this.props.match.params.id,
    };
  }

  componentDidMount() {
    const {barId} = this.state;

    if (_.isEmpty(this.props.barBeerList[barId])) {
      this.props.ActionGetBarBeerList(barId);
    }
  }

  renderBeerList = () => {
    const list = this.props.barBeerList || {};
    const barBeerList = list[this.state.barId] || {};
    const beerTypes = Object.keys(barBeerList);
    const searchStr = this.state.searchBeer;

    if (beerTypes.length < 1) {
      return [];
    }

    return beerTypes.map((beerType) => {
      const sectionItems = [];

      barBeerList[beerType].forEach((beer) => {
        if (beerType.toLowerCase() === 'beerchips') {
          return null;
        }

        if (searchStr.length > 2
          && beer.beer_name.toLowerCase()
            .indexOf(searchStr.toLowerCase()) < 0) {
          return null;
        }

        sectionItems.push(
          <Link to={buildRoute(URLS.BEER_DETAIL, beer.beer_id)} className="-block" key={beer.beer_id}>
            <div className="-image">
              <img src={beer.styleImage} alt="" />
            </div>
            <div className="-content">
              <div className="-title">{beer.beer_name} (ABV: {beer.abv}%)</div>
              <div className="-info">{beer.Style}</div>
              <div className="-descr">{beer.origin}</div>
              <div className="-points">{beer.beerChipCount} beerchip</div>
            </div>
          </Link>
        );
      });

      if (!sectionItems.length) return null;
      if (beerType === 'null') return null;

      return (
        <div className="section" key={beerType}>
          <div className="section_title">{beerType}</div>
          <div className="beers">
            {sectionItems}
          </div>
        </div>
      );
    });
  };

  onBeerSearch = (value) => {
    this.setState({
      searchBeer: value,
    });
  };

  render() {
    const {isLoading, BarMenuIsLoading} = this.props;
    const {barId, searchBeer} = this.state;
    const beerList = this.renderBeerList() || [];
    const beerListItems = Array.isArray(beerList) ? beerList.filter((item) => {
      if (item) return item;
    }) : [];

    return (
      <div className="app-container">
        <SimplyHeader title="Bar Detail" back={ROUTES.DASHBOARD} />
        <div className="app-content bar-detail">
          <BarDetailHead barId={barId} currentPage="beerList" />
          <div className="-tabs_content">
            <div className="-tabs_menu">
              <SearchInput
                placeholder="Search Beer"
                value={searchBeer}
                onChange={e => this.onBeerSearch(e.target.value)}
                cleanInput={() => this.onBeerSearch('')}
                searchList={[]}
                style={{marginBottom: '15px'}}
              />
              {beerListItems.length ? beerList : <NotFoundBlock title="Sorry! No beer found." />}
            </div>
          </div>
        </div>
        <Loader isLoading={isLoading || BarMenuIsLoading} />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionGetBarBeerList,
    ActionGetBarDetails,
    ActionModalShowLogin,
    ActionAddToFavoriteBar,
  }, dispatch);
}

function mapStateToProps({rServerData, rPages, rUser}) {
  return {
    rUser,
    barDetails: rServerData.barDetails || {},
    barBeerList: rServerData.barBeerList || {},
    isLoading: rPages.BarDetailPage.isLoading || false,
    BarMenuIsLoading: rPages.BarMenu.isLoading || false,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BarDetailBeerList);
