/*
* Author: Dima Bozhenko
* Date Created: 03.01.2019
* Date Modified: 15.03.2019
* Description: Modal component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {DIALOG_TYPES} from '../../constants/dialogs';
import ModalBeerRate from './templates/ModalBeerRate';
import ModalAgeConfirmNotSuccess from './templates/ModalAgeConfirmNotSuccess';
import ModalRegisterSuccess from './templates/ModalRegisterSuccess';
import ModalRegisterNotSuccess from './templates/ModalRegisterNotSuccess';
import ModalNotFoundUser from './templates/ModalNotFoundUser';
import ModalChangePasswordSuccess from './templates/ModalChangePasswordSuccess';
import ModalChangePasswordNotSuccess from './templates/ModalChangePasswordNotSuccess';
import ModalNotFoundAccount from './templates/ModalNotFoundAccount';
import ModalProfileUpdateSuccess from './templates/ModalProfileUpdateSuccess';
import ModalProfileUpdateNotSuccess from './templates/ModalProfileUpdateNotSuccess';
import ModalLogin from './templates/ModalLogin';
import ModalCollectBeerchip from './templates/ModalCollectBeerchip';
import ModalMapLink from './templates/ModalMapLink';
import ModalOpenHours from './templates/ModalOpenHours';
import ModalAddedBeerChip from './templates/ModalAddedBeerChip';
import './Modal.less';

class ModalPopup extends Component {
  renderModalByTemplate = () => {

    if (!this.props.rModals.templateType) {
      return '';
    }

    switch (this.props.rModals.templateType) {
    case DIALOG_TYPES.AGE_CONFIRM_NOT_SUCCESS:
      return <ModalAgeConfirmNotSuccess {...this.props.rModals.data} />;
    case DIALOG_TYPES.REGISTER_SUCCESS:
      return <ModalRegisterSuccess {...this.props.rModals.data} />;
    case DIALOG_TYPES.REGISTER_NOTSUCCESS:
      return <ModalRegisterNotSuccess {...this.props.rModals.data} />;
    case DIALOG_TYPES.CHANGE_PASSWORD_SUCCESS:
      return <ModalChangePasswordSuccess {...this.props.rModals.data} />;
    case DIALOG_TYPES.CHANGE_PASSWORD_NOTSUCCESS:
      return <ModalChangePasswordNotSuccess {...this.props.rModals.data} />;
    case DIALOG_TYPES.NOT_FOUND_USER:
      return <ModalNotFoundUser {...this.props.rModals.data} />;
    case DIALOG_TYPES.NOT_FOUND_ACCOUNT:
      return <ModalNotFoundAccount {...this.props.rModals.data} />;
    case DIALOG_TYPES.BEER_RATE:
      return <ModalBeerRate {...this.props.rModals.data} />;
    case DIALOG_TYPES.PROFILE_UPDATE_SUCCESS:
      return <ModalProfileUpdateSuccess {...this.props.rModals.data} />;
    case DIALOG_TYPES.PROFILE_UPDATE_NOTSUCCESS:
      return <ModalProfileUpdateNotSuccess {...this.props.rModals.data} />;
    case DIALOG_TYPES.LOGIN:
      return <ModalLogin {...this.props.rModals.data} />;
    case DIALOG_TYPES.COLLECT_BEERCHIP:
      return <ModalCollectBeerchip {...this.props.rModals.data} />;
    case DIALOG_TYPES.MAP_LINK:
      return <ModalMapLink {...this.props.rModals.data} />;
    case DIALOG_TYPES.OPEN_HOURS:
      return <ModalOpenHours {...this.props.rModals.data} />;
    case DIALOG_TYPES.BEERCHIP_ADDED:
      return <ModalAddedBeerChip {...this.props.rModals.data} />;
    default:
    }

    return '';
  };

  render() {
    return <div>{this.renderModalByTemplate()}</div>;
  }
}

function mapStateToProps({rModals}) {
  return {rModals};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPopup);
