/*
* Author: Dima Bozhenko
* Date Created: 14.03.2019
* Date Modified: 15.03.2019
* Description: Beer Detail Characteristic Block component
* Modification Log:
* License:
*/


import React, {Component} from 'react';
import PropTypes from 'prop-types';

class BeerDetailCharacteristicBlock extends Component {
  render() {
    const {name, value, full} = this.props;

    return (
      <div className={`-characteristic_block ${full ? 'full' : ''}`}>
        <div className="-name">{name}</div>
        <div className="-value">{value}</div>
      </div>
    );
  }
}

BeerDetailCharacteristicBlock.defaultProps = {
  name: '',
  value: '',
  full: false,
};

BeerDetailCharacteristicBlock.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  full: PropTypes.bool,
};

export default BeerDetailCharacteristicBlock;
