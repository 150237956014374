/*
* Author: Dima Bozhenko
* Date Created: 15.01.2019
* Date Modified: 15.03.2019
* Description: Header component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ActionRouteGoBack, ActionRouteNavigate} from '../../store/actions/actions-route';
import './Header.less';

class SimplyHeader extends Component {

  backClick = () => {
    const {back} = this.props;

    if (back) {
      this.props.ActionRouteNavigate(back);
    } else {
      this.props.ActionRouteGoBack();
    }
  };

  render() {
    return (
      <div className="header header--simply">
        <div className="-back" onClick={this.backClick} />
        <div className="-title">{this.props.title || ''}</div>
      </div>
    );
  }
}

SimplyHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionRouteGoBack,
    ActionRouteNavigate,
  }, dispatch);
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SimplyHeader);
