/*
* Author: Gennady Yatsenko
* Date Created: 04.02.2019
* Date Modified: 15.03.2019
* Description: Loader component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Loader.less';

class Loader extends Component {
  render() {
    if (!this.props.isLoading) return null;

    return (
      <div className="loader">
        <div className="loader__image" />
      </div>
    );
  }
}

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default Loader;
