/*
* Author: Dima Bozhenko
* Date Created: 24.01.2019
* Date Modified: 15.03.2019
* Description: SearchImput component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {buildRoute} from '../../../configs/routes';
import URL_MAP from '../../../constants/urls';

class SearchInput extends Component {

  onChangeInput = (e) => {
    this.props.onChange(e);
  };

  render() {
    const {placeholder, value, searchList, forwardedRef, isLoading} = this.props;

    return (
      <div className="search-field" style={this.props.style}>
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={this.onChangeInput}
          ref={forwardedRef}
        />
        {this.props.value &&
          <button
            className="search-field_clean"
            onClick={this.props.cleanInput}
          />
        }
        {!isLoading && <button className="search-field_search" onClick={this.props.searchAction} />}
        {isLoading && <span className="search-field_loading" />}
        {value && searchList.length > 0 &&
        <ul className="search-field_list">
          {this.props.searchList.map((item) => {
            return (
              <li key={item.id}>
                <Link to={buildRoute(URL_MAP.BEER_DETAIL, `${item.id}`)}>{item.name}</Link>
              </li>
            );
          })}
        </ul>
        }
      </div>
    );
  }
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  searchAction: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  cleanInput: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default SearchInput;
