/*
* Author: Dima Bozhenko
* Date Created: 07.02.2019
* Date Modified: 15.03.2019
* Description: Not Found component
* Modification Log:
* License:
*/

import React, {Component} from 'react';

class NotFoundBlock extends Component {
  render() {
    return (
      <div className="-notfound">
        <div className="-notfound_img" />
        {this.props.title &&
          <div className="-notfound_title">{this.props.title}</div>
        }
        {this.props.text &&
          <div className="-notfound_text">{this.props.text}</div>
        }
      </div>
    );
  }
}

export default NotFoundBlock;
