/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Constants
* Modification Log:
* License:
*/

export const FORM_INPUT_STATES = {
  INPUT_VALIDATION_STATE_ERROR: 'error',
  INPUT_VALIDATION_STATE_WARNING: 'warning',
};
