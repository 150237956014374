/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Viewport model
* Modification Log:
* License:
*/

function initialViewportModel() {
  return {
    showMainMenu: false,
  };
}

export {initialViewportModel};
