/*
* Author: Dima Bozhenko
* Date Created: 04.01.2019
* Date Modified: 10.04.2019
* Description: User api
* Modification Log:
* License:
*/

import $q from 'q';
import http from 'services/http';
import ConfigStorage from 'configs';
import API_MAP from 'configs/url-api';

const {API_HOST} = ConfigStorage().API_SETTINGS;


export default class User {
  static login(email, password) {
    const deferred = $q.defer();
    let responseBody = {
      success: false,
      userData: null,
      message: '',
    };
    const body = {
      email,
      password,
      beta: 0,
      conceptId: 7,
    };

    http.post(`${API_HOST}${API_MAP.SIGN_IN}`, body)
      .then((res) => {
        if (res.Data.Success) {
          responseBody.success = true;
          responseBody.userData = res.Data.ViewModel;
          responseBody.message = res.Data.Message;

          deferred.resolve(responseBody);
        }

        responseBody.message = res.Data.Message;
        deferred.reject(responseBody);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static facebookLogin(data) {
    const deferred = $q.defer();
    let responseBody = {
      success: false,
      message: '',
    };
    const body = {
      ...data,
    };

    http.post(`${API_HOST}${API_MAP.FACEBOOK}`, body)
      .then((res) => {
        if (res.Data.success) {
          responseBody.success = true;
          responseBody.UserId = res.Data.UserId;
          deferred.resolve(responseBody);
        }

        responseBody.message = res.Data.Message;
        deferred.reject(responseBody);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static signUp(fullName, email, password) {
    const deferred = $q.defer();
    let responseBody = {
      success: false,
      userData: null,
      message: '',
    };
    const body = {
      email,
      full_name: fullName,
      password,
      beta: 0,
      conceptId: 7,
    };

    http.post(`${API_HOST}${API_MAP.SIGN_UP}`, body)
      .then((res) => {
        deferred.resolve(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static forgotPassword(email) {
    const deferred = $q.defer();
    let responseBody = {
      success: false,
      userData: null,
      message: '',
    };
    const body = {
      email,
      beta: 0,
      conceptId: 7,
    };

    http.post(`${API_HOST}${API_MAP.FORGOT_PASSWORD}`, body)
      .then((res) => {
        deferred.resolve(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }

  static resetPassword(data) {
    const deferred = $q.defer();
    let responseBody = {
      success: false,
      userData: null,
      message: '',
    };

    const body = {
      ...data,
    };

    http.post(`${API_HOST}${API_MAP.RESET_PASSWORD}`, body)
      .then((res) => {
        deferred.resolve(res);
      })
      .catch((error) => {
        responseBody.error = error;
        deferred.reject(responseBody);
      });

    return deferred.promise;
  }
}
