/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Constants
* Modification Log:
* License:
*/

export const USER_ROLES = {};

export const STEP_STATES = {
  DONE: 'done',
  CURRENT: 'current',
};

export const SERVER_ACTIONS = {
  LOGIN: 'login',
  SIGNUP: 'signup',
};

export const USER_ACTIONS = {
  SENDING_REQUEST: 'SENDING_REQUEST',
  LOGGED_IN: 'LOGGED_IN',
  UPDATE: 'UPDATE_USER_DATA',
  CLEAR: 'CLEAR',
  SAVE: 'SAVE',
  REMIND_PASSWORD: 'REMIND_PASSWORD',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
};

export const LOGIN_PAGE_ACTIONS = {
  UPDATE: 'LOGIN_PAGE_UPDATE',
};

export const REMIND_PASSWORD_PAGE_ACTIONS = {
  UPDATE: 'UPDATE_REMIND_PASSWORD_PAGE_ACTIONS',
};

export const ERROR_REASONS = {
  USER_EXISTS: 'USER_EXISTS',
};

export const STORAGE_USER_NAMESPACE = 'storage_user_name_space';
