/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Modals reducer
* Modification Log:
* License:
*/

import ConstantsStorage from '../../constants/index';
import {initialModalModel} from '../models/modal-model';

const ACTIONS = ConstantsStorage().DIALOG_ACTIONS;

export default (state = initialModalModel(), action) => {
  switch (action.type) {
  case ACTIONS.DIALOG_SHOW:
    const data = (action.data) ? action.data : {};
    return {...state, templateType: action.template, data};

  case ACTIONS.DIALOG_HIDE:
    return {...initialModalModel()};

  case ACTIONS.DIALOG_SET_PARAMS:
    return {...state, data: action.data};

  default:
    return state;
  }
};
