/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Constants
* Modification Log:
* License:
*/

export const MOUSE_EVENTS = {
  MOUSE_OVER: 'MOUSE_OVER',
  MOUSE_OUT: 'MOUSE_OUT',
  DRAG_OVER: 'DRAG_OVER',
  DRAG_OUT: 'DRAG_OUT',
  DROPPED: 'DROPPED',
};

export const LOADER_EVENTS = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
};

export const STORAGE_TYPES = {
  LOCAL: 'local',
  SESSION: 'session',
  COOKIES: 'cookies',
};

export const STATUS_CODES = {
  UNAUTHORIZED: 401,
};

export const STATE_EVENTS = {
  CHANGE: 'change',
  ERROR: 'error',
};
