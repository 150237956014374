/*
* Author: Dima Bozhenko
* Date Created: 15.01.2019
* Date Modified: 15.03.2019
* Description: Header component
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ActionToggleSidebar} from '../../store/actions/actions-pages';
import './Header.less';

class Header extends Component {

  showSidebar = () => {
    this.props.ActionToggleSidebar(true);
  };

  render() {
    const {title, logo} = this.props;

    return (
      <div className="header">
        <div className="-burger" onClick={this.showSidebar}>
          <span />
          <span />
          <span />
        </div>
        {title && <div className="-title">{title}</div>}
        {logo && <div className="-logo" />}
        {/*<div className="-notification" />*/}
      </div>
    );
  }
}

Header.defaultProps = {
  logo: true,
  title: false,
};


function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionToggleSidebar,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
