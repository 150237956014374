/*
* Author: Gennadiy Yatsenko
* Date Created: 04.02.2019
* Date Modified: 08.05.2019
* Description: Favorites Beers page
* Modification Log:
* License:
*/

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import ROUTES, {buildRoute} from '../../configs/routes';
import URLS from '../../constants/urls';
import Header from '../../components/Header/Header';
import NavBar from '../../components/NavBar/NavBar';
import Loader from '../../components/Loader/Loader';
import {
  ActionGetFavoriteBeers,
} from '../../store/actions/actions-request';


class FavoritesBars extends Component {
  componentDidMount() {
    this.props.ActionGetFavoriteBeers();
  }

  render() {
    const {beers, isLoading} = this.props;
    const tabsData = [
      {
        route: ROUTES.FAVORITES_BARS,
        name: 'BARS',
      },
      {
        route: ROUTES.FAVORITES_BEERS,
        name: 'BEERS',
      },
      {
        route: ROUTES.FAVORITES_STYLES,
        name: 'STYLES',
      },
    ];

    return (
      <div className="app-container">
        <div className="app-content favorites">
          <Header logo={false} title="Favorites" />
          <div className="favorites_head">
            <NavBar activeIndex={1} data={tabsData} />
          </div>
          <div className="favorites_content">
            <div className="beers">
              {
                beers.map((item) => {
                  return (
                    <Link to={buildRoute(URLS.BEER_DETAIL, item.id)} className="-block" key={item.id}>
                      <div className="-image">
                        <img src={item.brewLogo} alt=""/>
                      </div>
                      <div className="-content">
                        <div className="-title">{item.name}</div>
                        <div className="-info">{item.style}</div>
                        <div className="-descr">{item.origin}</div>
                      </div>
                    </Link>
                  );
                })
              }
              {!beers.length && <div className="-title text-center" style={{textAlign: 'center', marginTop: '150px'}}> No Favorite Beer </div>}
            </div>
          </div>
        </div>
        <Loader isLoading={isLoading} />
      </div>
    );
  }
}

function mapStateToProps({rServerData, rPages}) {
  return {
    beers: rServerData.beers || [],
    isLoading: rPages.FavoritesPage.isLoading || false,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ActionGetFavoriteBeers,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesBars);
