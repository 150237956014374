/*
* Author: Gennadiy Yatsenko
* Date Created: 03.01.2019
* Date Modified: 14.03.2019
* Description: Constants
* Modification Log:
* License:
*/

import URL from './urls';
import DIALOG_ACTIONS, {DIALOG_TYPES} from './dialogs';
import VIEWPORT_ACTIONS, {BUTTON_TYPES} from './layout';
import {FORM_INPUT_STATES} from './form';
import {
  USER_ROLES,
  STEP_STATES,
  SERVER_ACTIONS as USER_SERVER_ACTIONS,
  USER_ACTIONS,
  LOGIN_PAGE_ACTIONS,
  STORAGE_USER_NAMESPACE,
} from './users';
import {ACTIONS as SESSION_ACTIONS, NAMESPACES as SESSION_NAMESPACES, STORAGE_SESSION_NAMESPACE} from './sessions';
import {ACTIONS as FLOWS_ACTIONS} from './flows';
import {ACTIONS as DATA_ACTIONS, STORAGE_SERVER_DATA_NAMESPACE} from './data';

export default function ConstantsStorage() {
  return {
    URL,
    DIALOG_ACTIONS,
    DIALOG_TYPES,
    BUTTON_TYPES,
    VIEWPORT_ACTIONS,
    USER_ROLES,
    STEP_STATES,
    USER_ACTIONS,
    USER_SERVER_ACTIONS,
    DATA_ACTIONS,
    FORM_INPUT_STATES,
    SESSION_ACTIONS,
    SESSION_NAMESPACES,
    LOGIN_PAGE_ACTIONS,
    STORAGE_SERVER_DATA_NAMESPACE,
    STORAGE_USER_NAMESPACE,
    STORAGE_SESSION_NAMESPACE,
    FLOWS_ACTIONS,
  };
}
